import React, { useState } from "react";
import Holdings from "./Holdings";

const HoldingsScreen = () => {
  const [sellPercentage, setSellPercentage] = useState(50);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "50px",
      }}
    >
      <Holdings />
    </div>
  );
};

export default HoldingsScreen;
