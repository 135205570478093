import React, { useEffect, useRef, useState, useMemo } from "react";
import { Web3ReactProvider, useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { StyleSheet, css } from "aphrodite";
import { Helmet } from "react-helmet";
import Trader from "./Trader";
import Header from "./Header";
import ClaimModal from "./ClaimModal";
import websocketService from "./Websocket.js";
import AppContext from "./AppContext";
import HoldingsScreen from "./HoldingsScreen";
import Leaderboard from "./Leaderboard";
import WithdrawModal from "./WithdrawModal";
import TokenScreen from "./TokenScreen";
import { origin } from "./utils";

const styles = StyleSheet.create({
  container: {
    fontFamily: "Lato, Arial",
    color: "white",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  header: {
    height: "80px",
    width: "100%",
    padding: "20px",
    boxSizing: "border-box",
  },
});

function getLibrary(provider, connector) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const pageMap = {
  dashboard: Trader,
  trader: Trader,
  holdings: HoldingsScreen,
  leaderboard: Leaderboard,
  tokenScreen: TokenScreen,
};
let count = 0;

const getSolPrice = async () => {
  try {
    const headers = { "Content-Type": "application/json" };
    const response = await fetch(`${origin}/sol_price`, {
      method: "GET",
      headers: headers,
    });
    return response.json(); // This should return the challenge
  } catch (err) {
    console.error("Failed to fetch new challenge:", err);
    return Promise.resolve({}); // Return an empty object on error
  }
};

const parseUrl = () => {
  const path = window.location.pathname.substring(1); // Remove leading "/"
  const segments = path.split("/").filter(Boolean); // Split and remove empty parts
  if (segments[0] === "tokens" && segments[1]) {
    return { page: "tokenScreen", mintId: segments[1] };
  }
  return { page: path || "dashboard", mintId: null };
};

const App = () => {
  const adapter = new PhantomWalletAdapter();
  let initialPage = window.location.pathname.substring(1) || "dashboard";
  let initialCoinSelected = [];
  if (initialPage.startsWith("tokens/")) {
    initialCoinSelected = { mint: initialPage.split("/")[1] };
    initialPage = "tokenScreen";
  }
  const [page, setPage] = useState(initialPage);
  const [width, setWidth] = useState(window.innerWidth); // check width size of the window
  const [isMobile, setIsMobile] = useState(false);
  const [coinSelected, setCoinSelected] = useState(initialCoinSelected);
  const [tokens, setTokens] = useState([]);
  const [socketOpen, setSocketOpen] = useState(false);
  const [showModal, setShowModal] = useState("");
  const [trades, setTrades] = useState({});
  const [account, setAccount] = useState({});
  const [authToken, setAuthToken] = useState(null);
  const [socket, setSocket] = useState(null);
  const [holdings, setHoldings] = useState([]);
  const [tokenHoldings, setTokenHoldings] = useState([]);
  const [user, setUser] = useState({});
  const [exchange, setExchange] = useState("Pump.fun");
  const [solPrice, setSolPrice] = useState(0);
  const [balance, setBalance] = useState(null);
  // You might store filters locally if you want to re-send them upon reconnect
  const [filters, setFilters] = useState([]);
  // Track manually minted addresses if you want local state
  const [manualMints, setManualMints] = useState([]);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
    setIsMobile(window.innerWidth < 1600);
  };

  // Update URL when page state changes
  useEffect(() => {
    if (page !== window.location.pathname.substring(1)) {
      if (page === "tokenScreen") {
        window.history.pushState({}, "", `/tokens/${coinSelected.mint}`);
      } else {
        window.history.pushState({}, "", `/${page}`);
      }
    }
  }, [page]);

  // Handle browser back/forward buttons
  useEffect(() => {
    const handlePopState = () => {
      setPage(window.location.pathname.substring(1) || "dashboard");
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  const openFn = (client) => {
    // const tokensToAdd = {
    //   add_filters: [
    //     {
    //       field: "successChance",
    //       operator: "gte",
    //       value: 0,
    //     },
    //   ],
    // };
    setSocketOpen(true);

    console.log("reached", client);
    // client.send(JSON.stringify(tokensToAdd));
    setSocket(client);
  };

  useEffect(() => {
    console.log(socket, filters, exchange);
    if (socket && (filters || exchange)) {
      const exch = [];
      if (exchange) {
        if (exchange === "Raydium") {
          exch.push({
            field: "hitRaydium",
            operator: "eq",
            value: true,
          });
        }
        if (exchange === "Pump.fun") {
          exch.push({
            field: "hitRaydium",
            operator: "eq",
            value: false,
          });
        }
      }
      const msg = { add_filters: [...filters, ...exch] };

      socket.send(JSON.stringify(msg));
    }
  }, [filters, exchange, socket]);

  // --- NEW: Methods to communicate with the backend WebSocket based on our server logic ---
  // 1) Add filters
  const addFilters = (newFilters) => {
    setFilters(newFilters);
  };

  // 2) Remove filters
  //    Our server code used 'remove_filters' in some examples, but you can adapt as needed
  const removeFilters = (socket, removed) => {
    const msg = { remove_filters: removed };
    socket.send(JSON.stringify(msg));
  };

  // 3) Add manual mints
  const addMints = (socket, mints) => {
    // For local usage
    setManualMints((prev) => [...prev, ...mints]);

    const msg = { add_mint: mints };
    socket.send(JSON.stringify(msg));
  };

  // 4) Remove manual mints
  const removeMints = (socket, mints) => {
    setManualMints((prev) => prev.filter((m) => !mints.includes(m)));

    const msg = { remove_mint: mints };
    socket.send(JSON.stringify(msg));
  };

  // TODO, if socket changes in useEffect then resend all holdings to be tracked manually

  // Handle incoming messages: we parse them & update our data as needed
  const onSocketMessage = (event) => {
    try {
      const msg = JSON.parse(event.data);

      switch (msg.type) {
        case "update": {
          if (Array.isArray(msg.tokens)) {
            console.log("Here is the tokens", msg.tokens);
            setTokens(msg.tokens);
          }
          break;
        }
        case "tokenHolding": {
          setTokenHoldings((tokenHoldings) => {
            let newTokenHoldings = [];
            let added = false;

            tokenHoldings.forEach((holding) => {
              if (holding.mint === msg.tokenHolding.mint) {
                newTokenHoldings.push(msg.tokenHolding);
                added = true;
              } else {
                newTokenHoldings.push(holding);
              }
            });

            if (!added) {
              newTokenHoldings.push(msg.tokenHolding);
            }

            console.log(
              "allholdings",
              JSON.stringify(newTokenHoldings, null, 2)
            );

            return newTokenHoldings;
          });
        }
        case "trackedUpdates": {
          if (Array.isArray(msg.tokens)) {
            console.log("Here are the tokens:", msg.tokens);

            setHoldings((prevHoldings) => {
              // 1. Create a Map of incoming tokens keyed by their mint
              const incomingMap = new Map(
                msg.tokens.map((token) => [token.mint, token])
              );

              // 2. Update existing holdings in a single pass
              const updatedHoldings = prevHoldings.map((existing) => {
                if (incomingMap.has(existing.mint)) {
                  // Overwrite with incoming token
                  const updated = incomingMap.get(existing.mint);
                  // Remove from map so we don't add it again in step 3
                  incomingMap.delete(existing.mint);
                  return updated;
                }
                // Otherwise leave the existing one as is
                return existing;
              });

              // 3. Add any leftover tokens from the map
              for (const leftoverToken of incomingMap.values()) {
                // It must be new, send it to track
                updatedHoldings.push(leftoverToken);
              }

              return updatedHoldings;
            });
          }
          break;
        }
        case "remove": {
          // 'msg.tokens' is an array of mints to remove
          if (Array.isArray(msg.tokens)) {
            setTokens((prevTokens) =>
              prevTokens.filter((t) => !msg.tokens.includes(t.mint))
            );
          }
          break;
        }
        default:
          console.log("Received unrecognized message type:", msg);
          break;
      }
    } catch (err) {
      console.error("Error parsing socket message:", err);
    }
  };

  // Invoked when socket connects
  const onSocketOpen = (client) => {
    // The connection is open and we can set up state
    setSocketOpen(true);
    setSocket(client);

    // Optionally re-send existing filters or manual mints
    // Here, we can do that if we want:
    if (filters.length) {
      const msg = { add_filters: filters };
      client.send(JSON.stringify(msg));
    }
    if (manualMints.length) {
      const msg = { add_mint: manualMints };
      client.send(JSON.stringify(msg));
    }
  };

  useEffect(() => {
    const fetchSolPrice = async () => {
      try {
        const sPrice = await getSolPrice();
        if (sPrice && sPrice.solPrice) {
          setSolPrice(sPrice.solPrice); // Only update state if the fetch is successful
        }
      } catch (e) {
        console.error("Failed to fetch SOL price:", e);
      }
    };

    // Call it immediately
    fetchSolPrice();

    // Set up the interval
    const intervalId = setInterval(fetchSolPrice, 100000);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  // const messageFn = (event) => {
  //   const receivedMessage = event.data;
  //   try {
  //     const messageObject = JSON.parse(receivedMessage);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const closeFn = () => {
    setSocket(null);
    setSocketOpen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();

    websocketService.addHandlers(openFn, onSocketMessage, closeFn);
    websocketService.connect();

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const Page = pageMap[page];

  return (
    <AppContext.Provider
      value={{
        tokens,
        setTokens,
        addFilters,
        setExchange,
        socket,
        isMobile,
        socketOpen,
        trades,
        account,
        setAccount,
        setCoinSelected,
        balance,
        setBalance,
        setPage,
        authToken,
        setAuthToken,
        solPrice,
        setSolPrice,
        user,
        setUser,
        setShowModal,
        coinSelected,
        setTrades,
        setHoldings,
        holdings,
        tokenHoldings,
        setTokenHoldings,
      }}
    >
      <Web3ReactProvider getLibrary={getLibrary}>
        <ConnectionProvider
          endpoint={
            "https://icy-warmhearted-uranium.solana-mainnet.quiknode.pro/d2aa282c332bf4835788c0515042e41cb67c95f9/"
          }
        >
          <WalletProvider wallets={[adapter]} autoConnect>
            <Helmet>
              <script
                type="text/javascript"
                src="/charting_library/charting_library.standalone.js"
              ></script>
              <script
                type="text/javascript"
                src="/datafeeds/udf/dist/bundle.js"
              ></script>
            </Helmet>
            <div className={css(styles.container)}>
              <div className={css(styles.header)}>
                <Header
                  page={page}
                  setPage={setPage}
                  setCoinSelected={setCoinSelected}
                />
              </div>
              <div
                style={{
                  height: isMobile ? "100%" : "calc(100svh - 80px)",
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  overflow: isMobile ? "scroll" : "hidden",
                  width: "100%",
                  padding: "0 20px 20px 20px",
                  scrollbarWidth: "none",
                }}
              >
                <Page
                  coinSelected={coinSelected}
                  setCoinSelected={setCoinSelected}
                />
                {showModal === "claim" && (
                  <ClaimModal closeModal={() => setShowModal(false)} />
                )}
                {(showModal === "withdraw" || showModal === "deposit") && (
                  <WithdrawModal
                    closeModal={() => setShowModal(false)}
                    initialState={showModal}
                  />
                )}
              </div>
            </div>
          </WalletProvider>
        </ConnectionProvider>
      </Web3ReactProvider>
    </AppContext.Provider>
  );
};
export default App;

/*

I can't simply just track tokens with the sockets normally, I need something different that tracks how much I am holding as it changes

I should just track the token with add_mints and use that data, but

*/
