import React, { useState } from "react";
import { css, StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
  coinContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
});

const TakeProfit = ({
  index,
  triggerPercentage,
  sellPercentage,
  remainingPercentage,
  remainingSOL,
  soldPercentage,
  soldSOL,
  onTriggerPercentageChange,
  onSellPercentageChange,
}) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <div style={{ fontSize: "12px", fontWeight: "600" }}>Trigger %</div>

          <div
            style={{
              fontSize: "12px",
              color: "rgba(255, 255, 255, 0.6)",
              marginTop: "5px",
            }}
          >
            Increase %
          </div>
        </div>
        <div
          style={{
            border: "1px solid rgba(255, 255, 255, 0.05)",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            display: "flex",
            gap: "20px",
            padding: "10px",
            position: "relative",
            width: "60px",
          }}
        >
          <input
            style={{ color: "white" }}
            placeholder="0"
            value={triggerPercentage || ""}
            onChange={(e) =>
              onTriggerPercentageChange(index, parseFloat(e.target.value) || 0)
            }
          />
        </div>

        <div>
          <div style={{ fontSize: "12px", fontWeight: "600" }}>Sell %</div>

          <div
            style={{
              fontSize: "12px",
              color: "rgba(255, 255, 255, 0.6)",
              marginTop: "5px",
            }}
          >
            Tokens
          </div>
        </div>
        <div
          style={{
            border: "1px solid rgba(255, 255, 255, 0.05)",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            display: "flex",
            gap: "20px",
            padding: "10px",
            position: "relative",
            width: "60px",
          }}
        >
          <input
            style={{ color: "white" }}
            placeholder="0"
            value={sellPercentage || ""}
            onChange={(e) =>
              onSellPercentageChange(index, parseFloat(e.target.value) || 0)
            }
          />
        </div>
      </div>

      <div
        style={{
          marginTop: "5px",
          borderRadius: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.05)",
          padding: "15px",
          fontSize: "12px",
          fontWeight: "600",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div>
          <span style={{ fontWeight: "400" }}>
            {remainingPercentage}% Remaining
          </span>{" "}
          - {remainingSOL} SOL
        </div>
        <div>
          <span style={{ fontWeight: "400" }}>{soldPercentage}% Sold</span> -{" "}
          {soldSOL} SOL
        </div>
      </div>
    </div>
  );
};

export default TakeProfit;
