import React, { useState } from "react";
import oldStyles from "./oldStyles";
import { css, StyleSheet } from "aphrodite";
import TraderCoin from "./TraderCoin";
import Token from "./Token";
import TokensHeader from "./TokensHeader";
import TraderHeader from "./TraderHeader";
import TakeProfit from "./TakeProfit";

const styles = StyleSheet.create({
  coinContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
});

const Strategy = ({ sol, setSol }) => {
  const [takeProfits, setTakeProfits] = useState([
    {
      triggerPercentage: 0,
      sellPercentage: 0,
      remainingPercentage: 100,
      remainingSOL: 1,
      soldPercentage: 0,
      soldSOL: 0,
    },
  ]);

  const [stopLossPercentage, setStopLossPercentage] = useState("");

  const addTakeProfit = () => {
    const lastTakeProfit = takeProfits[takeProfits.length - 1];
    setTakeProfits([
      ...takeProfits,
      {
        triggerPercentage: 0,
        sellPercentage: 0,
        remainingPercentage: lastTakeProfit.remainingPercentage,
        remainingSOL: lastTakeProfit.remainingSOL,
        soldPercentage: lastTakeProfit.soldPercentage,
        soldSOL: lastTakeProfit.soldSOL,
      },
    ]);
  };

  const handleTriggerPercentageChange = (index, newTriggerPercentage) => {
    const updatedTakeProfits = [...takeProfits];
    updatedTakeProfits[index].triggerPercentage = newTriggerPercentage;
    recalculateValues(updatedTakeProfits);
  };

  const handleSellPercentageChange = (index, newSellPercentage) => {
    const updatedTakeProfits = [...takeProfits];
    updatedTakeProfits[index].sellPercentage = newSellPercentage;
    recalculateValues(updatedTakeProfits);
  };

  const recalculateValues = (updatedTakeProfits) => {
    let initialSOL = 1; // Initial investment
    let soldPercentage = 0; // Accumulated sold percentage
    let totalSoldSOL = 0; // Accumulated sold SOL
    let remainingPercentage = 100; // Remaining percentage of the investment
    let remainingEquity = initialSOL; // Start with 100% equity value

    for (let i = 0; i < updatedTakeProfits.length; i++) {
      const triggerMultiplier =
        (updatedTakeProfits[i].triggerPercentage || 0) / 100;
      const sellPercentage = updatedTakeProfits[i].sellPercentage || 0;

      // Calculate the current equity value based on the latest trigger percentage
      const currentEquityValue = initialSOL * triggerMultiplier;

      // Calculate the amount sold at this step
      const saleProceeds = currentEquityValue * (sellPercentage / 100);

      // Update cumulative sold and remaining percentages
      totalSoldSOL += saleProceeds;
      soldPercentage += sellPercentage;
      remainingPercentage = 100 - soldPercentage;

      // Calculate remaining equity based on unsold percentage of the current equity value
      remainingEquity = currentEquityValue * (remainingPercentage / 100);

      // Update the current TakeProfit values
      updatedTakeProfits[i].remainingSOL = remainingEquity.toFixed(2);
      updatedTakeProfits[i].soldSOL = totalSoldSOL.toFixed(2);
      updatedTakeProfits[i].remainingPercentage =
        remainingPercentage.toFixed(2);
      updatedTakeProfits[i].soldPercentage = soldPercentage.toFixed(2);
    }

    setTakeProfits(updatedTakeProfits);
  };

  return (
    <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
      {/* SOL Buy Amount Section */}

      {/* BLURRED SECTION STARTS HERE */}

      {/* BLURRED SECTION ENDS HERE */}
    </div>
  );
};

export default Strategy;

/*


<div style={{ position: "relative" }}>
        <div
          style={{
            filter: "blur(3px) brightness(50%)",
            padding: "20px",
            borderRadius: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            pointerEvents: "none",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <div>
                <div style={{ fontSize: "12px", fontWeight: "600" }}>
                  Stop Loss (%)
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "rgba(255, 255, 255, 0.6)",
                    marginTop: "5px",
                  }}
                >
                  Sells all after falling x%
                </div>
              </div>
              <div
                style={{
                  border: "1px solid rgba(255, 255, 255, 0.05)",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  gap: "5px",
                  padding: "10px",
                  position: "relative",
                  width: "120px",
                }}
              >
                <input
                  style={{ color: "white" }}
                  placeholder="0"
                  value={stopLossPercentage || ""}
                  onChange={(e) => setStopLossPercentage(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>

          {takeProfits.map((profit, index) => (
            <TakeProfit
              key={index}
              index={index}
              triggerPercentage={profit.triggerPercentage}
              sellPercentage={profit.sellPercentage}
              remainingPercentage={profit.remainingPercentage}
              remainingSOL={profit.remainingSOL}
              soldPercentage={profit.soldPercentage}
              soldSOL={profit.soldSOL}
              onTriggerPercentageChange={handleTriggerPercentageChange}
              onSellPercentageChange={handleSellPercentageChange}
            />
          ))}

          <button
            style={{
              marginTop: "10px",
              padding: "10px 20px",
              backgroundColor: "#4caf50",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontWeight: "600",
            }}
            onClick={addTakeProfit}
          >
            Add Take Profit
          </button>
        </div>

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            pointerEvents: "none", // ensures overlay doesn't block blur behind
          }}
        >
          <span
            style={{
              color: "#fff",
              fontSize: "24px",
              fontWeight: "900",
              padding: "10px 20px",
              borderRadius: "8px",
            }}
          >
            <span style={{ fontSize: "16px" }}>Automated Trading</span>
            <br />
            <br />
            COMING SOON
          </span>
        </div>
      </div>

*/
