import React, { useContext, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { css, StyleSheet } from "aphrodite";
import AppContext from "./AppContext";
import {
  LAMPORTS_PER_SOL,
  Transaction,
  SystemProgram,
  sendAndConfirmTransaction,
  PublicKey,
} from "@solana/web3.js";
import { origin } from "./utils";

const styles = StyleSheet.create({
  primaryButton: {
    background: "rgb(32 129 226)",
    borderRadius: "20px",
    padding: "10px 20px",
    fontWeight: "900",
    minWidth: "400px",
    textAlign: "center",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgb(69, 145, 222)",
    },
  },
  input: {
    padding: "10px",
    border: "1px solid #42444f",
    borderRadius: "10px",
    backgroundColor: "#0f1018",
    color: "white",
    fontSize: "16px",
    width: "100%",
  },
  tabContainer: {
    display: "flex",
    gap: "10px",
    justifyContent: "center",
  },
  tab: {
    padding: "10px 20px",
    cursor: "pointer",
    fontWeight: "bold",
    color: "white",
    background: "#1c1e27",
    borderRadius: "10px",
    transition: "0.3s",
    ":hover": {
      background: "#2a2d3a",
    },
  },
  activeTab: {
    background: "rgb(32 129 226)",
  },
});

const withdraw = async (recipient, solAmount) => {
  try {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found. Verify signature first.");
      return;
    }

    const response = await fetch(`${origin}/withdraw`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ recipient, sol_amount: solAmount }),
    });

    const data = await response.json();

    if (response.ok) {
      console.log("Withdraw successful:", data);
    } else {
      console.error("Withdraw failed:", data.error);
    }
  } catch (err) {
    console.error("Error accessing withdraw endpoint:", err);
  }
};

const deposit = async (
  fromPubkey,
  toPubkey,
  solAmount,
  sendTransaction,
  connection
) => {
  try {
    console.log(
      "called",
      fromPubkey,
      toPubkey,
      solAmount,
      sendTransaction,
      connection
    );

    const transaction = new Transaction().add(
      SystemProgram.transfer({
        fromPubkey,
        toPubkey, // Send to user's Solana keypair
        lamports: solAmount * LAMPORTS_PER_SOL,
      })
    );

    console.log("tx", transaction, connection);

    const signature = await sendTransaction(transaction, connection.connection);
    console.log("Deposit transaction sent, signature:", signature);
  } catch (err) {
    console.error("Error processing deposit:", err);
  }
};

const WithdrawModal = ({ closeModal, initialState }) => {
  const { publicKey, sendTransaction } = useWallet();
  const { user } = useContext(AppContext);
  const connection = useConnection();

  const [tab, setTab] = useState(initialState);
  const [solAmount, setSolAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const recipient =
    tab === "withdraw" ? user.address : user.solanaKeypair.publicKey;

  const handleAction = async () => {
    if (!solAmount) {
      alert("Please enter an amount.");
      return;
    }

    setLoading(true);
    if (tab === "withdraw") {
      await withdraw(recipient, parseFloat(solAmount));
    } else {
      await deposit(
        publicKey,
        new PublicKey(user.solanaKeypair.publicKey),
        parseFloat(solAmount),
        sendTransaction,
        connection
      );
    }
    setLoading(false);
    closeModal();
  };

  return (
    <div
      style={{
        width: "100%",
        height: "calc(100svh - 80px)",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        onClick={closeModal}
        style={{
          position: "absolute",
          width: "100vw",
          height: "calc(100svh - 80px)",
          zIndex: 20,
          backdropFilter: "blur(5px)",
        }}
      />
      <div
        style={{
          background: "#0f1018",
          border: "1px solid #42444f",
          borderRadius: "10px",
          zIndex: 30,
          minWidth: "600px",
          alignItems: "center",
          padding: "30px 50px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className={css(styles.tabContainer)}>
          <div
            className={css(styles.tab, tab === "withdraw" && styles.activeTab)}
            onClick={() => setTab("withdraw")}
          >
            Withdraw
          </div>
          <div
            className={css(styles.tab, tab === "deposit" && styles.activeTab)}
            onClick={() => setTab("deposit")}
          >
            Deposit
          </div>
        </div>
        <h2 style={{ color: "white", textAlign: "center", fontWeight: "600" }}>
          {tab === "withdraw" ? "Withdraw SOL" : "Deposit SOL"}
        </h2>
        <input
          type="text"
          placeholder="Recipient Wallet Address"
          value={recipient}
          disabled={tab === "deposit"}
          className={css(styles.input)}
        />
        <input
          type="number"
          placeholder="Amount (SOL)"
          value={solAmount}
          onChange={(e) => setSolAmount(e.target.value)}
          className={css(styles.input)}
        />
        <button
          onClick={handleAction}
          className={css(styles.primaryButton)}
          disabled={loading}
        >
          {loading
            ? "Processing..."
            : tab === "withdraw"
            ? "Withdraw"
            : "Deposit"}
        </button>
        <button onClick={closeModal} className={css(styles.primaryButton)}>
          Close
        </button>
      </div>
    </div>
  );
};

export default WithdrawModal;
