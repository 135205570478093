import React, { useState } from "react";
import { css, StyleSheet } from "aphrodite";

const appStyles = {
  menuBack: {
    width: "100%",
    backgroundColor: "#171b1c",
    boxSizing: "border-box",
    overflow: "hidden",
    transition: "height 100ms ease 0s",
  },
  menuBackInner: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  menuLink: {
    color: "white",
    fontFamily: "Lato, Arial",
    paddingBottom: "10px",
    marginBottom: "10px",
    borderBottom: "1px solid #414c4d",
    fontWeight: "600",
  },
};

const styles = StyleSheet.create({
  navButton: {
    color: "rgb(106, 106, 109)",
    textDecoration: "none",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      color: "rgb(255, 255, 255)",
    },
  },
  menuOption: {
    backgroundColor: "rgba(255,255,255,.04)",
    border: "1px solid rgba(255,255,255,.05)",
    padding: "8px 15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    cursor: "pointer",
    textDecoration: "none",
    ":hover": {
      backgroundColor: "rgba(255,255,255,.08)",
    },
  },
});

const HamburgerMenu = ({ setPage }) => {
  const [menuExpanded, setMenuExpanded] = useState(false);

  return (
    <div
      style={{ position: "relative" }}
      onClick={() => setMenuExpanded((m) => !m)}
    >
      <div
        style={{
          width: "40px",
          height: "4px",
          borderRadius: "5px",
          backgroundColor: "#DDD",
        }}
      />
      <div
        style={{
          width: "40px",
          height: "4px",
          borderRadius: "5px",
          backgroundColor: "#DDD",
          marginTop: "8px",
        }}
      />
      <div
        style={{
          width: "40px",
          height: "4px",
          borderRadius: "5px",
          backgroundColor: "#DDD",
          marginTop: "8px",
        }}
      />
      <Menu menuExpanded={menuExpanded} setPage={setPage} />
    </div>
  );
};

const Menu = ({ menuExpanded, setPage }) => {
  const items = [
    {
      name: "Dashboard",
      slug: "dashboard",
    },
    {
      name: "Holdings",
      slug: "holdings",
    },
    {
      name: "Leaderboard",
      slug: "leaderboard",
    },
    {
      name: "Docs",
      href: "https://docs.flakeswap.com/",
    },
  ];
  return (
    <div
      style={{
        ...appStyles.menuBack,
        height: menuExpanded ? "initial" : 0,
        position: "absolute",
        zIndex: 50,
        top: 50,
        right: "0px",
        width: menuExpanded ? "calc(100vw - 40px)" : 0,
      }}
    >
      <div
        style={{
          padding: "20px",
          borderRadius: "20px",
          width: "100%",
          backgroundColor: "#0f1018",
          border: "1px solid #42444f",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {items.map((item) => {
          if (item.href) {
            return (
              <a
                style={{ textDecoration: "none" }}
                href={item.href}
                target={"_blank"}
              >
                <div className={css(styles.menuOption)}>{item.name}</div>
              </a>
            );
          } else {
            return (
              <div
                onClick={() => setPage(item.slug)}
                className={css(styles.menuOption)}
              >
                {item.name}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default HamburgerMenu;
