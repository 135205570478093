import React, { useEffect, useState, useRef, useContext } from "react";
import Datafeed from "./datafeed.js";
import AppContext from "./AppContext";

// const TradingViewWidget = () => {
//     const [tradingViewExists, setTradingViewExists] = useState(false)
//     const {coinSelected} = useContext(AppContext)

//     useEffect(() => {
//         console.log("df", Datafeed)
//         const waitForTradingView = setInterval(() => {
//             if (window.TradingView) {
//                 console.log("Trading View exists")
//                 setTradingViewExists(true)
//                 clearInterval(waitForTradingView)
//             } else {
//                 console.log("Trading View does not exist")
//             }
//         }, 100)
//     }, [])

//     useEffect(() => {
//         if (tradingViewExists) {
//             const newWidget = new window.TradingView.widget({
//                 autosize: true,
//                 symbol: `${coinSelected.token_address || coinSelected.tokenAddress}:${coinSelected.symbol}/USD`,
//                 interval: '1',
//                 fullscreen: false,
//                 container: 'tradingview-widget-container',
//                 datafeed: Datafeed,
//                 library_path: 'charting_library/',
//                 theme: "dark",
//                 precision: 4,
//                 disabled_features: [
//                     'header_compare',
//                     'compare_symbol',
//                     'header_symbol_search',
//                     'symbol_search_hot_key'
//                     ],
//               overrides: {
//                 "paneProperties.backgroundGradientStartColor": "#020024",
//                 "paneProperties.backgroundGradientEndColor": "#4f485e",
//                 precision: 4,
//               },
//             })
//         }
//     }, [tradingViewExists])
//     return (
//         <div id="tradingview-widget-container" style={{ height: "100%", width: "100%" }}>
//             Loading...
//         </div>
//     )
// }

const TradingViewWidget = ({ coinSelected }) => {
  const [scriptAppended, setScriptAppended] = useState(false);
  const container = useRef(null);

  //   const { name } = coinSelected;

  const name = "hello";

  useEffect(() => {
    if (!scriptAppended) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "autosize": true,
          "datafeedURL": "https://demo-feed-data.tradingview.com",
          "symbol": "COINBASE:SOLUSD",
          "interval": "1",
          "timezone": "Etc/UTC",
          "theme": "dark",
          "style": "2",
          "locale": "en",
          "enable_publishing": false,
          "allow_symbol_change": true,
          "calendar": false
        }`;

      container.current.appendChild(script);
      setScriptAppended(true); // Update state to indicate script appended
    }
  }, [scriptAppended]);

  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={{ height: "100%", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default TradingViewWidget;
