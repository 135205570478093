import React, { useState } from "react";
import { css, StyleSheet } from "aphrodite";

const Filter = ({ title, from, setFrom, to, setTo }) => {
  return (
    <div>
      <div style={{ color: "rgba(255, 255, 255, 0.8)", fontSize: "14px" }}>
        {title}
      </div>
      <div
        style={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "10px",
        }}
      >
        <div
          style={{
            border: "1px solid rgba(255, 255, 255, 0.05)",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            display: "flex",
            gap: "20px",
            padding: "10px",
            position: "relative",
            width: "120px",
          }}
        >
          <input
            style={{ color: "white" }}
            placeholder="0"
            value={from || ""}
            onChange={(e) => setFrom(parseFloat(e.target.value))}
          />
        </div>

        <div style={{ fontSize: "12px", fontWeight: "600" }}>to</div>
        <div
          style={{
            border: "1px solid rgba(255, 255, 255, 0.05)",
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            display: "flex",
            gap: "20px",
            padding: "10px",
            position: "relative",
            width: "120px",
          }}
        >
          <input
            style={{ color: "white" }}
            placeholder="0"
            value={to || ""}
            onChange={(e) => setTo(parseFloat(e.target.value))}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
