export function commafy(num) {
  var str = num.toString().split(".");
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ");
  }
  return str.join(".");
}

export const normalizeNumber = (value) => {
  if (!value || isNaN(value)) return 0; // Handle empty or non-numeric input
  return parseFloat(value); // Convert to a proper number
};

export const formatToThreeSignificantDigits = (num) => {
  const trueNum = Number(num);
  if (isNaN(trueNum)) {
    return "0";
  }

  // Define thresholds and suffixes in an array
  const scales = [
    { threshold: 1e12, suffix: "t" },
    { threshold: 1e9, suffix: "b" },
    { threshold: 1e6, suffix: "m" },
    { threshold: 1e3, suffix: "k" },
  ];

  // Determine the appropriate scale
  for (let i = 0; i < scales.length; i++) {
    if (Math.abs(trueNum) >= scales[i].threshold) {
      return (trueNum / scales[i].threshold).toPrecision(3) + scales[i].suffix;
    }
  }

  // If the number is less than 1 and not zero, adjust the precision for leading zeros
  if (Math.abs(trueNum) < 1 && trueNum !== 0) {
    const numStr = trueNum.toString();
    const index = numStr.indexOf(".") + 1;
    const significantDigits = numStr.slice(index).match(/^(0*)[1-9]/);
    const leadingZeros = significantDigits ? significantDigits[1].length : 0;

    // Calculate total significant digits to include
    return trueNum.toPrecision(1 + leadingZeros);
  }

  // If no scale is appropriate, use the basic number format
  return trueNum.toPrecision(3);
};

const toNonExponential = (num) => {
  var m = num.toExponential().match(/\d(?:\.(\d+))?e([+-]\d+)/);
  return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
};
export const handleTooManyZeroes = (num) => {
  if (num >= 1) {
    return {
      format: false,
    };
  }
  const strNum = toNonExponential(num);
  const [firstZero, afterDecimal] = strNum.split(".");
  let zeroCount = 0;
  for (let i = 0; i < afterDecimal.length; i++) {
    if (afterDecimal[i] === "0") {
      zeroCount += 1;
    }
  }

  if (zeroCount < 3) {
    return {
      format: false,
    };
  }

  return {
    format: true,
    zeroCount: zeroCount,
    numberAfter: afterDecimal.substr(zeroCount),
  };
};

export const formatToXSignificantDigits = (num, x) => {
  const trueNum = Number(num);
  if (isNaN(trueNum)) {
    return "0";
  }

  // Helper function to remove trailing zeros
  const removeTrailingZeros = (numberStr) => {
    // Remove trailing zeros only if there's a decimal point
    if (numberStr.includes(".")) {
      numberStr = numberStr.replace(/\.?0+$/, "");
    }
    return numberStr;
  };

  // Define thresholds and suffixes in an array
  const scales = [
    { threshold: 1e12, suffix: "t" },
    { threshold: 1e9, suffix: "b" },
    { threshold: 1e6, suffix: "m" },
    { threshold: 1e3, suffix: "k" },
  ];

  // Determine the appropriate scale
  for (let i = 0; i < scales.length; i++) {
    if (Math.abs(trueNum) >= scales[i].threshold) {
      return removeTrailingZeros(
        (trueNum / scales[i].threshold).toPrecision(x) + scales[i].suffix
      );
    }
  }

  // If the number is less than 1 and not zero, adjust the precision for leading zeros
  if (Math.abs(trueNum) < 1 && trueNum !== 0) {
    const numStr = trueNum.toString();
    const index = numStr.indexOf(".") + 1;
    const significantDigits = numStr.slice(index).match(/^(0*)[1-9]/);
    const leadingZeros = significantDigits ? significantDigits[1].length : 0;

    // Calculate total significant digits to include
    return removeTrailingZeros(trueNum.toPrecision(x + leadingZeros));
  }

  // If no scale is appropriate, use the basic number format
  return removeTrailingZeros(trueNum.toPrecision(x));
};

export const origin =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3003"
    : "https://ws.flakeswap.com";
