import React, { useContext, useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import {
  commafy,
  origin,
  formatToThreeSignificantDigits,
  formatToXSignificantDigits,
  normalizeNumber,
} from "./utils";
import AppContext from "./AppContext";
import Toast from "./Toast";
import tokensHeader from "./TokensHeader";
import { ImageWithFallback } from "./ImageWithFallback";

const styles = StyleSheet.create({
  holdingContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    padding: "15px",
    width: "100%",
    minHeight: "94px",
    display: "inline-flex",
    gap: "15px",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
  holdingContainerMini: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
    padding: "15px",
    width: "100%",
    minHeight: "94px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
});

let toastId = 1;

function formatNumber(value) {
  if (typeof value !== "number") {
    throw new Error("Input must be a number");
  }

  let suffix = "";
  let formattedValue = value;

  if (value >= 1_000_000) {
    suffix = "m";
    formattedValue = value / 1_000_000;
  } else if (value >= 1_000) {
    suffix = "k";
    formattedValue = value / 1_000;
  }

  const roundedValue =
    formattedValue >= 10
      ? Math.round(formattedValue)
      : parseFloat(formattedValue.toFixed(1));

  return `${roundedValue}${suffix}`;
}

function shortenString(str, startLength = 4, endLength = 4) {
  if (str.length <= startLength + endLength) {
    return str; // No truncation needed if the string is too short
  }
  const start = str.slice(0, startLength);
  const end = str.slice(-endLength);
  return `${start}...${end}`;
}

const Holding2 = ({
  isMini,
  holding,
  tokenHolding,
  sellPercentage,
  buyAmount,
}) => {
  const { solPrice, setCoinSelected, setPage } = useContext(AppContext);
  const [toasts, setToasts] = useState([]);

  if (!holding || !tokenHolding) {
    return <></>;
  }

  const { mint, tokenMetadata, marketCap } = holding;

  const {
    name,
    amount,
    symbol,
    decimals,
    isSol,
    tokenAddress,
    image,
    flakeImage,
  } = tokenMetadata;

  tokenHolding.amountRemaining =
    (holding.marketCap / 1_000_000_000) * tokenHolding.quantity;
  tokenHolding.pnl =
    ((tokenHolding.amountRemaining + tokenHolding.amountSold) /
      tokenHolding.amountInvested -
      1) *
    100;

  const {
    quantity,
    solAmount,
    amountInvested,
    amountRemaining,
    amountSold,
    pnl,
  } = tokenHolding;

  const finalImage = flakeImage || image;

  const realMarketCap = solPrice * marketCap;

  const renderToast = (toast) => {
    return (
      <Toast
        key={toast.id}
        title={toast.title}
        information={toast.information}
        icon={toast.icon}
        link={toast.link}
      />
    );
  };

  const renderToasts = () => {
    return <div>{toasts.map(renderToast)}</div>;
  };

  const sell = async (tokenAmount, mint) => {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Verify signature first.");
        return;
      }

      setToasts((t) => [
        ...t,
        {
          title: "Initiating Sell...",
          information: `Sell attempted, please wait`,
          icon: "check",
          id: toastId++,
        },
      ]);

      // Make a request to the protected endpoint
      const response = await fetch(`${origin}/sell`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          token_amount: tokenAmount,
          mint,
        }),
      });

      const data = await response.json();

      if (data.success) {
        setToasts((t) => [
          ...t,
          {
            title: "Sell Transaction",
            information: `Signature: ${shortenString(data.signature)}`,
            link: {
              uri: `https://solscan.io/tx/${data.signature}`,
              text: "View On Solscan",
            },
            icon: "check",
            id: toastId++,
          },
        ]);
      }

      if (response.ok) {
        console.log("Protected endpoint response:", data);
      } else {
        console.error("Failed to access protected endpoint:", data.error);
      }
    } catch (err) {
      console.error("Error accessing protected endpoint:", err);
    }
  };

  const copy = (mint) => {
    navigator.clipboard
      .writeText(mint)
      .then(() => {
        console.log("Copied to clipboard:", mint);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
    setToasts((t) => [
      ...t,
      {
        title: "Copied to clipboard",
        information: `Successfully copied!`,
        icon: "check",
        id: toastId++,
      },
    ]);
  };

  const handleSell = (percentage) => {
    sell(Math.floor(percentage * quantity), mint);
  };

  const buy = async (solAmount, mint) => {
    console.log("wtf", localStorage.getItem("authToken"));
    if (!localStorage.getItem("authToken")) {
      setToasts((t) => [
        ...t,
        {
          title: "Not Connected",
          information: `You must connect and fund your wallet`,
          icon: "fail",
          id: toastId++,
        },
      ]);
      return;
    }
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Verify signature first.");
        return;
      }

      // Make a request to the protected endpoint
      const response = await fetch(`${origin}/buy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sol_amount: solAmount,
          mint,
        }),
      });

      const data = await response.json();

      if (data.success) {
        setToasts((t) => [
          ...t,
          {
            title: "Buy Transaction",
            information: `Signature: ${shortenString(data.signature)}`,
            link: {
              uri: `https://solscan.io/tx/${data.signature}`,
              text: "View On Solscan",
            },
            icon: "check",
            id: toastId++,
          },
        ]);
      }

      if (response.ok) {
        console.log("Protected endpoint response:", data);
      } else {
        console.error("Failed to access protected endpoint:", data.error);
      }
    } catch (err) {
      console.error("Error accessing protected endpoint:", err);
    }
  };

  const handleBuy = (amount) => {
    buy(amount, mint);
  };

  const profit =
    Number(amountRemaining) + Number(amountSold) - Number(amountInvested);

  if (isMini) {
    return (
      <div
        className={css(styles.holdingContainerMini)}
        onClick={(e) => {
          e.preventDefault();
          setCoinSelected(holding);
          setPage("tokenScreen");
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              alignItems: "center",
            }}
          >
            {renderToasts()}
            <div>
              <ImageWithFallback
                imageUri={finalImage || `${window.location.origin}/missing.png`}
                fallbackUri={`${window.location.origin}/missing.png`}
                name={name}
                size={40}
              />
            </div>
            <div
              style={{
                fontWeight: "700",
                fontSize: "14px",
                textAlign: "center",
                marginTop: "5px",
              }}
            >{`$${formatNumber(Math.floor(Number(realMarketCap)))}`}</div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              gap: "5px",
              width: "100px",
            }}
          >
            <div>
              <div
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: "16px",
                  marginBottom: "5px",
                }}
              >
                {symbol}
              </div>
              <div
                style={{
                  color: "rgba(255, 255, 255, 0.5)",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                }}
              >{`${formatNumber(Math.floor(quantity))} ${symbol}`}</div>
            </div>
            <div
              style={{
                color: "white",
                fontWeight: "400",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              <img
                src={`${window.location.origin}/sol.png`}
                alt="Solana Logo"
                style={{
                  borderRadius: "50%",
                  width: "17px", // Adjust size as needed
                  height: "17px",
                }}
              />
              {Number(amountRemaining).toFixed(3)}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSell(sellPercentage / 100);
            }}
            style={{
              padding: "5px",
              backgroundColor: "rgba(255, 255, 255, 0.05)",
              display: "inline-flex",
              borderRadius: "20px",
              justifySelf: "flex-end",
              width: "100px",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              position: "relative", // Needed for z-index to work
              zIndex: 10, // Higher value to ensure it sits above other elements
              border: "none", // Remove default button border
              color: "white", // Ensure text is visible
              fontWeight: "700", // Match text styling
              fontSize: "14px",
              transition: "background-color 0.3s", // Optional: smooth hover effect
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.1)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor =
                "rgba(255, 255, 255, 0.05)";
            }}
          >
            🏷️
            <span style={{ paddingLeft: "5px" }}>{`Sell ${normalizeNumber(
              sellPercentage
            )}%`}</span>
          </button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <div
              style={{
                fontSize: "14px",
                color: Number(pnl) >= 0 ? "#4fff69" : "#ff3800",
              }}
            >
              {`${Number(pnl) >= 0 ? "+" : ""}${Math.round(Number(pnl))}%`}
            </div>
            <div
              style={{
                color: "rgba(255, 255, 255, 0.5)",
                fontWeight: "400",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              {profit >= 0 ? "+" : "-"}
              {`${Math.abs(Number(profit)).toFixed(3)}`}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={css(styles.holdingContainer)}
      onClick={(e) => {
        e.preventDefault();
        setCoinSelected(holding);
        setPage("tokenScreen");
      }}
    >
      {renderToasts()}
      <div>
        <ImageWithFallback
          imageUri={finalImage || `${window.location.origin}/missing.png`}
          fallbackUri={`${window.location.origin}/missing.png`}
          name={name}
          size={50}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "100px",
        }}
      >
        <div style={{ color: "white", fontWeight: "700" }}>{symbol}</div>
        <div
          style={{
            color: "#666",
            fontSize: "13px",
            display: "flex",
            gap: "5px",
          }}
        >
          {shortenString(mint)}
          <button
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              opacity: 0.8, // Slightly transparent
            }}
            onClick={() => copy(mint)}
          >
            <img
              src={`${window.location.origin}/copying.png`}
              alt="Copy Icon"
              height={14}
              style={{
                filter: "invert(100%)", // Makes the image white
                opacity: 0.8, // Matches the button transparency
              }}
            />
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
          marginLeft: "50px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "14px" }}>
            Invested
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "700",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <img
              src={`${window.location.origin}/sol.png`}
              alt="Solana Logo"
              style={{
                borderRadius: "50%",
                width: "20px", // Adjust size as needed
                height: "20px",
              }}
            />
            {Number(amountInvested).toFixed(5)}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "14px" }}>
            {`${formatNumber(Math.floor(quantity))} Remaining`}
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "700",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <img
              src={`${window.location.origin}/sol.png`}
              alt="Solana Logo"
              style={{
                borderRadius: "50%",
                width: "20px", // Adjust size as needed
                height: "20px",
              }}
            />
            {Number(amountRemaining).toFixed(5)}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "14px" }}>
            Sold
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "700",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            <img
              src={`${window.location.origin}/sol.png`}
              alt="Solana Logo"
              style={{
                borderRadius: "50%",
                width: "20px", // Adjust size as needed
                height: "20px",
              }}
            />
            {Number(amountSold).toFixed(5)}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div style={{ color: "rgba(255, 255, 255, 0.6)", fontSize: "14px" }}>
            Market Cap
          </div>
          <div
            style={{
              color: "white",
              fontWeight: "700",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
            }}
          >
            {`$${formatNumber(Math.floor(Number(realMarketCap)))}`}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div
            style={{
              color: "rgba(255, 255, 255, 0.6)",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              gap: "5px",
            }}
          >
            P&L Percentage
          </div>
          <div
            style={{
              color: Number(pnl) >= 0 ? "#4fff69" : "#ff3800",
              fontSize: "14px",
            }}
          >
            {`${Number(pnl) >= 0 ? "+" : ""}${Math.round(Number(pnl))}%`}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          width: "150px",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <div
            style={{
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "14px",
            }}
          >
            Sol Profit / Loss
          </div>
          <div
            style={{
              color: Number(pnl) >= 0 ? "#4fff69" : "#ff3800",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              gap: "5px",
            }}
          >
            <img
              src={`${window.location.origin}/sol.png`}
              alt="Solana Logo"
              style={{
                borderRadius: "50%",
                width: "20px", // Adjust size as needed
                height: "20px",
              }}
            />
            {`${profit >= 0 ? "+" : ""}${profit.toFixed(5)}`}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifySelf: "flex-end",
          flexGrow: 1,
          textAlign: "right",
        }}
      >
        <div
          style={{
            color: "rgba(255, 255, 255, 0.6)",
            fontSize: "12px",
          }}
        >
          Profit
        </div>
        <div
          style={{
            color: Number(pnl) >= 0 ? "#4fff69" : "#ff3800",
            fontWeight: "700",
            fontSize: "14px",
          }}
        >
          {`${Number(pnl) >= 0 ? "+" : "-"}$${Math.abs(
            formatToThreeSignificantDigits(profit * solPrice)
          )}`}
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleSell(sellPercentage / 100);
          }}
          style={{
            padding: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            display: "inline-flex",
            borderRadius: "20px",
            justifySelf: "flex-end",
            width: "100px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            position: "relative", // Needed for z-index to work
            zIndex: 10, // Higher value to ensure it sits above other elements
            border: "none", // Remove default button border
            color: "white", // Ensure text is visible
            fontWeight: "700", // Match text styling
            fontSize: "14px",
            transition: "background-color 0.3s", // Optional: smooth hover effect
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.05)";
          }}
        >
          <span>{`Sell ${normalizeNumber(sellPercentage)}%`}</span>
        </button>
        {/* <button
          onClick={() => handleBuy(buyAmount)}
          style={{
            padding: "5px",
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            display: "inline-flex",
            gap: "5px",
            borderRadius: "20px",
            justifySelf: "flex-end",
            width: "100px",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            position: "relative", // Needed for z-index to work
            zIndex: 10, // Higher value to ensure it sits above other elements
            border: "none", // Remove default button border
            color: "white", // Ensure text is visible
            fontSize: "14px",
            fontWeight: "700", // Match text styling
            transition: "background-color 0.3s", // Optional: smooth hover effect
          }}
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.05)";
          }}
        >
          <img
            style={{ borderRadius: "100%" }}
            width={20}
            height={20}
            src={`${window.location.origin}/sol.png`}
          />
          <span>{`${normalizeNumber(buyAmount)}`}</span>
        </button> */}
      </div>
    </div>
    //</a>
  );
};

export default Holding2;
