import React, { useContext, useEffect, useState } from "react";
import SearchResult from "./SearchResult";
import AppContext from "./AppContext";
import { origin } from "./utils";

const searchTokens = async ({ searchTerm }) => {
  try {
    const headers = { "Content-Type": "application/json" };
    const response = await fetch(`${origin}/search?query=${searchTerm}`, {
      method: "GET",
      headers: headers,
    });
    return response.json();
  } catch (err) {
    return Promise.resolve({});
  }
};
const Search = ({ onFocus, onBlur, searchFocused }) => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { isMobile, setCoinSelected, setPage } = useContext(AppContext);
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300); // Adjust debounce time (milliseconds) as needed

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    findTokens(debouncedSearch);
  }, [debouncedSearch]);

  const findTokens = async (searchTerm) => {
    const res = await searchTokens({ searchTerm });
    console.log("res", res);
    setSearchResults(res.tokens);
  };

  return (
    <div
      style={{
        border: "1px solid rgba(255, 255, 255, 0.05)",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        display: "flex",
        gap: "20px",
        padding: "10px",
        position: "relative",
      }}
    >
      <img src={`${window.location.origin}/search.png`} height={20} />
      <input
        onFocus={onFocus}
        onBlur={onBlur}
        style={{ color: "white", minWidth: isMobile ? "100px" : "400px" }}
        placeholder={"Search"}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {searchFocused && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px",
            backgroundColor: "#0f1018",
            border: "1px solid #42444f",
            borderRadius: "10px",
            position: "absolute",
            top: "70px",
            left: "-25px",
            zIndex: 300,
          }}
        >
          <div style={{ fontWeight: "700", fontSize: "16px" }}>Tokens</div>
          <div
            style={{
              maxHeight: "50vh",
              width: "500px",
              scrollbarWidth: "none",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {searchResults.map((coin, idx) => (
              <SearchResult
                key={coin.mint}
                handleClick={() => {
                  setSearch("");
                  setCoinSelected(coin);
                  setPage("tokenScreen");
                }}
                coin={coin}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
