import React, {useContext, useState} from "react";
import {css, StyleSheet} from "aphrodite";
import AppContext from "./AppContext";
import {ImageWithFallback} from "./ImageWithFallback.js"
import {formatToThreeSignificantDigits, formatToXSignificantDigits, origin} from './utils.js'
const tokenGridTemplateColumns = '300px minmax(40px, 1fr)'

const styles = StyleSheet.create({
    coinContainer: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '10px',
        transition: '0.3s all',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
        },
    },
})


const Token = ({handleClick, coin}) => {
    const [showRewardInfo, setShowRewardInfo] = useState(false)
    const {isMobile, trades} = useContext(AppContext)

    const {name, historical_prices, last_price_in_usd, symbol, value, fdv, image_uri, image, token_address, multiplier, verified, warnings} = coin
    const priceOfSol = Number(trades['So11111111111111111111111111111111111111112']?.[0]?.price_of_token_in_usd) || 0
    const priceOfTokenInSol = Number(trades[token_address]?.[0]?.price_of_token_in_sol) || 0

    const bonusOn = multiplier > 1

    const currentPrice = Number(priceOfSol * priceOfTokenInSol)
    const {price_5_min_ago, price_1_hour_ago, price_1_day_ago} = historical_prices

    const price_change_5_min = (((currentPrice / price_5_min_ago) * 100) - 100).toFixed(2)
    const price_change_1_hour = (((currentPrice / price_1_hour_ago) * 100) - 100).toFixed(2)
    const price_change_1_day = (((currentPrice / price_1_day_ago) * 100) - 100).toFixed(2)

    const showWarning = !verified && warnings.length > 0

    return (
        <div onClick={handleClick} className={!isMobile ? css(styles.coinContainer) : null}>
            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                <div style={{display: 'grid', gridTemplateColumns: tokenGridTemplateColumns, padding: '10px', alignItems: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '10px', overflow: 'hidden', boxSizing: 'border-box'}}>
                        <div>
                            {/*<img style={{borderRadius: '50%'}} height={30} width={30} src={image_uri || 'https://i.imgur.com/LNGtWS9.png'} alt={`${name} icon`} />*/}
                            <ImageWithFallback
                                imageUri={image || image_uri || `${window.location.origin}/missing.png`}
                                fallbackUri={`${window.location.origin}/missing.png`}
                                name={name}
                                size={30}
                            />
                        </div>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'center', maxWidth: '150px', overflow: 'hidden', whiteSpace: 'pre-wrap'}}>
                            { verified &&
                                (<div>
                                    <img height={20} width={20} src={`${window.location.origin}/verified.png`} />
                                </div>)
                            }
                            { showWarning &&
                                (<div>
                                    <img height={20} width={20} src={`${window.location.origin}/warning.png`} />
                                </div>)
                            }
                            <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                                <div style={{color: 'white', fontWeight: '700', overflow: 'hidden'}}>
                                    {name}
                                </div>
                                <div style={{color: 'rgb(106, 106, 109)', fontSize: '12px'}}>
                                    {symbol}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{color: 'white', fontSize: '14px', paddingLeft: '10px', textAlign: 'right', display: 'flex', flexDirection: 'column', gap: '5px'}}>
                        <div style={{fontWeight: '700'}}>
                            {'$' + formatToXSignificantDigits(currentPrice, 5)}
                        </div>
                        <div style={{color: price_change_1_day >= 0 ? '#4fff69' : '#ff3800', fontSize: '12px', textAlign: 'right'}}>
                            {`${price_change_1_day >= 0 ? '+' : ''}${formatToThreeSignificantDigits(price_change_1_day)}%`}
                        </div>
                    </div>
                    {/*<div style={{color: price_change_5_min >= 0 ? '#4fff69' : '#ff3800', fontSize: '12px', textAlign: 'right'}}>*/}
                    {/*    {`${price_change_5_min >= 0 ? '+' : ''}${formatToThreeSignificantDigits(price_change_5_min)}%`}*/}
                    {/*</div>*/}
                    {/*<div style={{color: price_change_1_hour >= 0 ? '#4fff69' : '#ff3800', fontSize: '12px', textAlign: 'right'}}>*/}
                    {/*    {`${price_change_1_hour >= 0 ? '+' : ''}${formatToThreeSignificantDigits(price_change_1_hour)}%`}*/}
                    {/*</div>*/}

                    {/*{bonusOn && (*/}
                    {/*    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', textAlign: 'right'}}>*/}
                    {/*        <div onMouseOver={() => setShowRewardInfo(true)} onMouseOut={() => setShowRewardInfo(false)} style={{display: 'flex', gap: '5px', alignItems: 'center', position: 'relative', backgroundColor: 'rgba(255, 255, 255, 0.05)', border: '1px solid rgba(255,255,255,.05)', padding: '8px', borderRadius: '10px' }}>*/}
                    {/*            <img src={`${window.location.origin}/flake.png`} height={16} />*/}
                    {/*            { showRewardInfo &&*/}
                    {/*                (<div style={{color: 'rgba(255, 255, 255, 0.7)', boxShadow: 'rgba(0, 0, 0, 0.4) 0px 4px 12px', position: 'absolute', whiteSpace: 'pre', fontSize: '14px', top: '40px', zIndex: 10, left: '-275px', display: 'flex', gap: '5px', alignItems: 'center', backgroundColor: '#0f1018', border: '1px solid #35353f', padding: '12px',borderRadius: '10px', transition: '0.3s all'}}>*/}
                    {/*                    {`Receive ${multiplier} times`}*/}
                    {/*                    <img src={`${window.location.origin}/flake.png`} height={16} />*/}
                    {/*                    for trading via FlakeSwap*/}
                    {/*                </div>)*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>

            </div>
        </div>
    )
}

export default Token