import React, { useContext, useState, useEffect } from "react";
import { css, StyleSheet } from "aphrodite";
import AppContext from "./AppContext";
import Toast from "./Toast";
import { ImageWithFallback } from "./ImageWithFallback.js";
import {
  formatToThreeSignificantDigits,
  formatToXSignificantDigits,
  normalizeNumber,
  origin,
} from "./utils.js";
const tokenGridTemplateColumns =
  "200px minmax(150px, 200px) repeat(6, minmax(100px, 160px)) minmax(140px, 1fr)";

const styles = StyleSheet.create({
  coinContainer: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "10px",
    transition: "0.3s all",
    minWidth: "100%",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
  },
});

let toastId = 1;

function shortenString(str, startLength = 4, endLength = 4) {
  if (str.length <= startLength + endLength) {
    return str; // No truncation needed if the string is too short
  }
  const start = str.slice(0, startLength);
  const end = str.slice(-endLength);
  return `${start}...${end}`;
}

const ProgressBar = ({
  percentage = 55,
  barHeight = "50px",
  barWidth = "20px",
  backgroundColor = "rgba(0, 0, 0, 0.5)",
  textColor = "white",
  labelColor = "rgb(153,153,157)",
  fontSize = "14px",
  saturation = 60,
  lightness = 60,
  showWarningThreshold = null,
  showWarningDirection = "above",
  // New prop to receive the correct thresholds
  thresholdConfig = null,
  isLocked,
}) => {
  const [filled, setFilled] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const validPercentage = Math.max(0, Math.min(percentage, 100));

  // Calculate the hue based on the percentage (0° red, 120° green)
  const hue = (validPercentage / 100) * 120;
  const fillColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;

  // A color scheme for each bucket label:
  const bucketColors = {
    BAD: "hsl(0, 65%, 35%)", // Strong Dark Red
    SUS: "hsl(25, 60%, 40%)", // Richer Orange
    MID: "hsl(50, 50%, 45%)", // Warmer Gold
    GOOD: "hsl(90, 50%, 40%)", // Strong Olive Green
    GREAT: "hsl(120, 55%, 35%)", // Deep Green
  };

  // Determine the tag and its color based on the "thresholdConfig" buckets
  const getTagInfo = (percentage) => {
    // If no thresholdConfig is provided, fall back to an empty or default label
    if (!thresholdConfig) {
      return { label: "N/A", color: "#888" };
    }

    // Destructure the "buckets" from thresholdConfig
    const { buckets } = thresholdConfig;

    // For each bucket, check if 'percentage' is in range
    // Example: "BAD": [90, 100]
    for (const [bucketLabel, [min, max]] of Object.entries(buckets)) {
      if (percentage >= min && percentage <= max) {
        return {
          label: bucketLabel,
          color: bucketColors[bucketLabel] || "#888",
        };
      }
    }

    // If no range matched, return a fallback
    return { label: "N/A", color: "#888" };
  };

  const tagInfo = getTagInfo(validPercentage);

  useEffect(() => {
    setFilled(validPercentage);

    if (showWarningThreshold !== null) {
      setShowWarning(
        showWarningDirection === "above"
          ? validPercentage > showWarningThreshold
          : validPercentage < showWarningThreshold
      );
    }
  }, [validPercentage, showWarningThreshold, showWarningDirection]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {/* Vertical progress bar */}
      <div
        role="progressbar"
        aria-valuenow={validPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{
          position: "relative",
          width: barWidth,
          height: barHeight,
          backgroundColor: backgroundColor,
          borderRadius: "4px",
          overflow: "hidden",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
        title={`${validPercentage}%`}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            height: `${filled}%`,
            backgroundColor: fillColor,
            transition: "height 0.5s ease, background-color 0.5s ease",
          }}
        />
        {/* {isLocked && (
          <div
            style={{
              position: "absolute",
              top: "12px",
              left: "-2px",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              borderRadius: "50%",
              padding: "4px",
            }}
          >
            🔒
          </div>
        )} */}
      </div>

      {/* Percentage + Warning + Tag */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          color: labelColor,
          fontSize: fontSize,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {/* {showWarning && (
            <img
              height={15}
              width={15}
              src={`${window.location.origin}/warning.png`}
              alt="Warning"
            />
          )} */}
          {isLocked && <span>🔒</span>}
          <div style={{ color: textColor, fontWeight: "700" }}>
            {`${validPercentage}%`}
          </div>
        </div>

        {/* Tag Display */}
        <span
          style={{
            padding: "4px 8px",
            borderRadius: "12px",
            fontSize: "12px",
            fontWeight: "900",
            color: "#fff",
            backgroundColor: tagInfo.color,
            textAlign: "center",
            width: "fit-content",
          }}
        >
          {tagInfo.label}
        </span>
      </div>
    </div>
  );
};

const CircularProgress = ({ percent = 50, size = 80, strokeWidth = 8 }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percent / 100) * circumference;

  const strokeColor = percent === 100 ? "green" : `rgba(0, 180, 255, 1)`; // Transition from transparent black to green

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      {/* Background Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke="rgba(0, 0, 0, 0.5)"
        strokeWidth={strokeWidth}
      />
      {/* Progress Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="none"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`} // Rotates so it starts at the top
      />
    </svg>
  );
};

const TraderCoin = ({ coin, sol }) => {
  const [showRewardInfo, setShowRewardInfo] = useState(false);
  const {
    isMobile,
    trades,
    setPage,
    setCoinSelected,
    balance,
    setShowModal,
    solPrice,
  } = useContext(AppContext);
  const [toasts, setToasts] = useState([]);

  let {
    tokenMetadata,
    mint,
    createdAt,
    updatedAt,
    hitRaydium,
    hasMint,
    walletAddressCount,
    allTimeHigh,
    insidersProcessed,
    marketCap,
    tokensAccountedFor,
    remainingTokens,
    volume,
    insider,
    newWallets,
    scam,
    whale,
    bot,
    successChance,
  } = coin;

  const { name, symbol, description, image, flakeImage } = tokenMetadata;
  const created_at = createdAt;
  const token_address = mint;

  tokensAccountedFor ||= 1;

  const botPercentage = (bot / tokensAccountedFor) * 100;
  const insiderPercentage = (insider / tokensAccountedFor) * 100;
  const newWalletsPercentage = (newWallets / tokensAccountedFor) * 100;
  const scamPercentage = (scam / tokensAccountedFor) * 100;
  const whalePercentage = (whale / tokensAccountedFor) * 100;
  const successPercentage = successChance * 100;

  const marketCapReal = Math.floor((marketCap * solPrice) / 1000);

  const volumeReal = Math.floor(volume);

  // successChance

  let thresholds = {
    bot: {
      improveDirection: "lower",
      buckets: {
        BAD: [90, 100], // All bots can be a bad sign, but a large amount is fine
        SUS: [80, 90],
        MID: [70, 80],
        GOOD: [60, 70],
        GREAT: [0, 60],
      },
    },
    insider: {
      improveDirection: "higher",
      buckets: {
        BAD: [0, 1],
        SUS: [1, 2],
        MID: [2, 5],
        GOOD: [5, 10],
        GREAT: [10, 100],
      },
    },
    newWallets: {
      improveDirection: "lower",
      buckets: {
        BAD: [90, 100],
        SUS: [70, 90],
        MID: [50, 70],
        GOOD: [20, 50],
        GREAT: [0, 20],
      },
    },
    scam: {
      improveDirection: "lower",
      buckets: {
        BAD: [90, 100],
        SUS: [80, 90],
        MID: [70, 80],
        GOOD: [50, 70],
        GREAT: [0, 50],
      },
    },
    whale: {
      improveDirection: "higher",
      buckets: {
        BAD: [0, 10],
        SUS: [10, 20],
        MID: [20, 30],
        GOOD: [30, 50],
        GREAT: [50, 100],
      },
    },
    successChance: {
      improveDirection: "higher",
      buckets: {
        BAD: [0, 20],
        SUS: [20, 40],
        MID: [40, 60],
        GOOD: [60, 80],
        GREAT: [80, 100],
      },
    },
    buyRating: {
      improveDirection: "higher",
      buckets: {
        "SCAM LIKELY": [0, 1],
        "POSSIBLE SCAM": [1, 10],
        "MIXED SIGNALS": [10, 20],
        "WORTH WATCHING": [20, 40],
        "POTENTIAL BUY": [40, 100],
      },
    },
  };

  function getBuyRating(thresholds, successChanc) {
    const { buckets } = thresholds.buyRating;
    // Iterate through each named bucket
    for (const rating in buckets) {
      const [min, max] = buckets[rating];
      // Check if successChanc falls within the [min, max) range
      if (successChanc >= min && successChanc < max) {
        return rating;
      }
    }
    return null; // or "Out of range"
  }

  // Initialize state for percentages and success chance
  const [insiderPerc, setInsiderPerc] = useState(Math.round(insiderPercentage));
  const [newWalletPerc, setNewWalletPerc] = useState(
    Math.round(newWalletsPercentage)
  );
  const [scamPerc, setScamPerc] = useState(Math.round(scamPercentage));
  const [whalePerc, setWhalePerc] = useState(Math.round(whalePercentage));
  const [botPerc, setBotPerc] = useState(Math.round(botPercentage));
  const [successChanc, setSuccessChance] = useState(
    Math.round(successPercentage)
  );

  const formatVolume = (num) => {
    if (num >= 1_000_000) {
      return `${(num / 1_000_000).toFixed(1)}m`; // Convert to millions
    } else if (num >= 1_000) {
      return `${(num / 1_000).toFixed(1)}k`; // Convert to thousands
    } else {
      return num.toString(); // Return as a string if smaller than 1,000
    }
  };

  const renderToast = (toast) => {
    return (
      <Toast
        key={toast.id}
        title={toast.title}
        information={toast.information}
        icon={toast.icon}
        link={toast.link}
      />
    );
  };

  const renderToasts = () => {
    return <div style={{ zIndex: 1000009 }}>{toasts.map(renderToast)}</div>;
  };

  const formattedVolume = formatVolume(volumeReal);

  const priceOfSol =
    Number(
      trades["So11111111111111111111111111111111111111112"]?.[0]
        ?.price_of_token_in_usd
    ) || 0;
  const priceOfTokenInSol =
    Number(trades[token_address]?.[0]?.price_of_token_in_sol) || 0;

  // Function to calculate age
  const calculateAge = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffInMs = now - createdDate;

    const seconds = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) return `${years}y ${months % 12}m`;
    if (months > 0) return `${months}m ${days % 30}d`;
    if (days > 0) return `${days}d ${hours % 24}h`;
    if (hours > 0) return `${hours}h ${minutes % 60}m`;
    if (minutes > 0) return `${minutes}m ${seconds % 60}s`;
    return `${seconds}s`;
  };

  // Initialize age state
  const [age, setAge] = useState(calculateAge(coin.createdAt));

  // Set up effect to update age
  useEffect(() => {
    // Update age immediately in case created_at changes
    setAge(calculateAge(coin.createdAt));

    // Define the interval (e.g., every second)
    const intervalId = setInterval(() => {
      setAge(calculateAge(coin.createdAt));
    }, 1000); // Update every second

    // Clean up the interval on component unmount or when created_at changes
    return () => clearInterval(intervalId);
  }, [created_at]);

  const handleBuy = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isMigrating) {
      return;
    }
    if (balance / 1_000_000_000 >= sol) {
      buy(sol, coin.mint);
    } else {
      setToasts((t) => [
        ...t,
        {
          title: "Deposit Required",
          information: `You must fund your wallet before buying`,
          icon: "fail",
          id: toastId++,
        },
      ]);
      setShowModal("deposit");
    }
  };

  const buy = async (solAmount, mint) => {
    console.log("wtf", localStorage.getItem("authToken"));
    if (!localStorage.getItem("authToken")) {
      setToasts((t) => [
        ...t,
        {
          title: "Not Connected",
          information: `You must connect and fund your wallet`,
          icon: "fail",
          id: toastId++,
        },
      ]);
      return;
    }
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No token found. Verify signature first.");
        return;
      }

      // Make a request to the protected endpoint
      const response = await fetch(`${origin}/buy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          sol_amount: solAmount,
          mint,
        }),
      });

      const data = await response.json();

      if (data.success) {
        setToasts((t) => [
          ...t,
          {
            title: "Buy Transaction",
            information: `Signature: ${shortenString(data?.signature)}`,
            link: {
              uri: `https://solscan.io/tx/${data?.signature}`,
              text: "View On Solscan",
            },
            icon: "check",
            id: toastId++,
          },
        ]);
      }

      if (response.ok) {
        console.log("Protected endpoint response:", data);
      } else {
        console.error("Failed to access protected endpoint:", data.error);
      }
    } catch (err) {
      console.error("Error accessing protected endpoint:", err);
    }
  };

  const copy = (mint) => {
    navigator.clipboard
      .writeText(mint)
      .then(() => {
        console.log("Copied to clipboard:", mint);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
    setToasts((t) => [
      ...t,
      {
        title: "Copied to clipboard",
        information: `Successfully copied!`,
        icon: "check",
        id: toastId++,
      },
    ]);
  };

  const tags = [
    "SCAM LIKELY",
    "POSSIBLE SCAM",
    "UNKNOWN",
    "WEAK SIGNAL",
    "POTENTIAL BUY",
    "STRONG BUY",
  ];

  const tagColors = {
    "SCAM LIKELY": "#FF4D4D", // Deep Red
    "POSSIBLE SCAM": "#FF914D", // Burnt Orange
    "MIXED SIGNALS": "#6C757D", // Cool Gray
    "POTENTIAL BUY": "#4DA6FF", // Light Blue
    "WORTH WATCHING": "#28C76F", // Vibrant Green
  };

  const isMigrating = hitRaydium && !hasMint;

  const randomTag = getBuyRating(thresholds, successChanc);

  const shouldShowPumpFun = coin.tokenMetadata.createdOn === "https://pump.fun";
  const shouldShowRaydium = coin.hitRaydium === true;

  return (
    // <a href={`https://pump.fun/coin/${mint}`} target="_blank">
    <div
      onClick={() => {
        setCoinSelected(coin);
        setPage("tokenScreen");
      }}
      className={!isMobile ? css(styles.coinContainer) : null}
    >
      {renderToasts()}
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: tokenGridTemplateColumns,
            padding: "10px",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              overflow: "hidden",
              boxSizing: "border-box",
            }}
          >
            <div style={{ position: "relative", display: "inline-block" }}>
              <CircularProgress
                percent={
                  hitRaydium
                    ? 100
                    : ((1e9 - remainingTokens) / (1e9 - 206900000)) * 100
                }
                size={60}
                strokeWidth={6}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <ImageWithFallback
                  imageUri={
                    coin.tokenMetadata.flakeImage ||
                    coin.tokenMetadata.image ||
                    `${window.location.origin}/missing.png`
                  }
                  fallbackUri={`${window.location.origin}/missing.png`}
                  name={coin.tokenMetadata.name}
                  size={50}
                />
              </div>
              {shouldShowPumpFun && (
                <a
                  href={`https://pump.fun/coin/${mint}`}
                  target="_blank"
                  style={{ display: "inline-block" }} // Ensures transform works correctly
                >
                  <div
                    style={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: 20,
                      height: 20,
                      backgroundColor: "black",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 2,
                      transition: "transform 0.2s ease-in-out",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.4)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <img
                      src={`${window.location.origin}/pump.webp`}
                      alt="Pump.fun"
                      style={{
                        width: 14,
                        height: 14,
                      }}
                    />
                  </div>
                </a>
              )}

              {/* Raydium Logo Overlay (Bottom Right) with Black Circle Background */}
              {shouldShowRaydium && (
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: 20,
                    height: 20,
                    backgroundColor: "black",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2,
                  }}
                >
                  <img
                    src={`${window.location.origin}/ray.png`}
                    alt="Raydium"
                    style={{
                      width: 14,
                      height: 14,
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                width: "150px",
                overflow: "hidden",
                whiteSpace: "pre-wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontWeight: "700",
                    overflow: "hidden",
                  }}
                >
                  {`$${coin.tokenMetadata.symbol}`}
                </div>
                <div
                  style={{
                    color: "rgb(106, 106, 109)",
                    fontSize: "12px",
                    display: "flex",
                    gap: "5px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    copy(coin.mint);
                  }}
                >
                  {shortenString(coin.mint)}
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      opacity: 0.8, // Slightly transparent
                    }}
                  >
                    <img
                      src={`${window.location.origin}/copying.png`}
                      alt="Copy Icon"
                      height={14}
                      style={{
                        filter: "invert(100%)", // Makes the image white
                        opacity: 0.8, // Matches the button transparency
                      }}
                    />
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    paddingLeft: "4px",
                    alignItems: "center", // Optional: Align items vertically in the center
                  }}
                >
                  {coin.tokenMetadata.telegram && (
                    <a
                      style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        cursor: "pointer",
                        display: "inline-block", // Ensures transform works correctly
                      }}
                      href={coin.tokenMetadata.telegram}
                      target="_blank"
                    >
                      <img
                        src={`${window.location.origin}/telegram.png`}
                        alt="Telegram"
                        width={14}
                        height={14}
                        style={{
                          filter: "invert(100%)",
                          opacity: 0.4,
                          transition: "transform 0.2s ease-in-out",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "scale(1.4)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      />
                    </a>
                  )}

                  {coin.tokenMetadata.twitter && (
                    <a
                      style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        cursor: "pointer",
                        display: "inline-block",
                      }}
                      href={coin.tokenMetadata.twitter}
                      target="_blank"
                    >
                      <img
                        src={`${window.location.origin}/twitter.png`}
                        alt="Twitter"
                        width={14}
                        height={14}
                        style={{
                          filter: "invert(100%)",
                          opacity: 0.4,
                          transition: "transform 0.2s ease-in-out",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "scale(1.4)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      />
                    </a>
                  )}

                  {coin.tokenMetadata.website && (
                    <a
                      style={{
                        background: "none",
                        border: "none",
                        padding: 0,
                        cursor: "pointer",
                        display: "inline-block",
                      }}
                      href={coin.tokenMetadata.website}
                      target="_blank"
                    >
                      <img
                        src={`${window.location.origin}/web.png`}
                        alt="Website"
                        width={14}
                        height={14}
                        style={{
                          filter: "invert(100%)",
                          opacity: 0.4,
                          transition: "transform 0.2s ease-in-out",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "scale(1.4)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              /* First column is 60px wide, second column auto-expands */
              gridTemplateColumns: "40px auto",
              gap: "8px",
              width: 150,
              fontSize: 14,
              overflow: "hidden",
              whiteSpace: "pre-wrap",
            }}
          >
            {/* Row 1: Age */}
            <div
              style={{
                textAlign: "right",
                color: "rgba(255, 255, 255, 0.6)",
                fontSize: 12,
              }}
            >
              Age
            </div>
            <div
              style={{
                color: "white",
                overflow: "hidden",
                paddingLeft: "5px",
                fontSize: "12px",
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              {age}
            </div>

            {/* Row 2: MCap */}
            <div
              style={{
                textAlign: "right",
                color: "rgba(255, 255, 255, 0.6)",
                fontSize: 14,
              }}
            >
              MCap
            </div>
            <div
              style={{
                fontWeight: 900,
                color: "rgba(0, 180, 255, 1)",
                fontSize: "16px",
                overflow: "hidden",
                paddingLeft: "5px",
              }}
            >
              {`$${marketCapReal}k`}
            </div>

            {/* Row 3: Volume */}
            <div
              style={{
                textAlign: "right",
                color: "rgba(255, 255, 255, 0.6)",
                fontSize: 12,
              }}
            >
              Vol
            </div>
            <div
              style={{
                color: "white",
                overflow: "hidden",
                fontSize: "12px",
                paddingLeft: "5px",
                color: "rgba(255, 255, 255, 0.8)",
              }}
            >
              {`$${formattedVolume}`}
            </div>
          </div>
          {/* Pass the matching threshold object as a prop named "thresholdConfig" (or any name you prefer) */}
          {tokensAccountedFor > 100_000_000 && (
            <>
              <ProgressBar
                percentage={insiderPerc}
                label="Smart Money"
                thresholdConfig={thresholds.insider}
                isLocked={hitRaydium} // 🔒
              />
              <ProgressBar
                percentage={newWalletPerc}
                label="New Wallet"
                thresholdConfig={thresholds.newWallets}
                isLocked={hitRaydium} // 🔒
              />
              <ProgressBar
                percentage={scamPerc}
                label="Scam"
                showWarningThreshold={10}
                thresholdConfig={thresholds.scam}
                isLocked={hitRaydium} // 🔒
              />
              <ProgressBar
                percentage={whalePerc}
                label="Whale"
                thresholdConfig={thresholds.whale}
                isLocked={hitRaydium} // 🔒
              />
              <ProgressBar
                percentage={botPerc}
                label="Bot"
                showWarningThreshold={50}
                thresholdConfig={thresholds.bot}
                isLocked={hitRaydium} // 🔒
              />
              <ProgressBar
                percentage={successChanc}
                label="Success"
                showWarningThreshold={20}
                showWarningDirection="below"
                thresholdConfig={thresholds.successChance}
                isLocked={hitRaydium} // 🔒
              />
            </>
          )}
          {tokensAccountedFor <= 100_000_000 && (
            <>
              <div style={{ color: "rgba(255, 255, 255, 0.6)" }}>
                Insufficient Holders
              </div>
              <div />
              <div />
              <div />
              <div />
              <div />
            </>
          )}
          <div
            style={{
              justifySelf: "flex-end",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: "10px",
            }}
          >
            <button
              onClick={handleBuy}
              disabled={isMigrating}
              style={{
                padding: "5px",
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                display: "inline-flex",
                borderRadius: "20px",
                width: "100px",
                alignItems: "center",
                justifyContent: "center",
                cursor: isMigrating ? "not-allowed" : "pointer",
                position: "relative", // Needed for z-index to work
                zIndex: 10, // Higher value to ensure it sits above other elements
                border: "none", // Remove default button border
                color: "white", // Ensure text is visible
                fontWeight: "700", // Match text styling
                transition: "background-color 0.3s", // Optional: smooth hover effect
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.1)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor =
                  "rgba(255, 255, 255, 0.05)";
              }}
            >
              {isMigrating && <div>Migrating...</div>}
              {!isMigrating && (
                <>
                  <img
                    src={`${window.location.origin}/sol.png`}
                    alt="Solana Logo"
                    style={{
                      borderRadius: "50%",
                      width: "24px", // Adjust size as needed
                      height: "24px",
                      marginRight: "8px", // Space between image and number
                    }}
                  />
                  <span>{normalizeNumber(sol) || 0}</span>
                </>
              )}
            </button>

            <div
              style={{
                borderRadius: "12px",
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <span
                key={randomTag}
                style={{
                  padding: "6px 12px",
                  borderRadius: "16px",
                  fontSize: "14px",
                  fontWeight: "900",
                  color: "#fff",
                  backgroundColor: tagColors[randomTag],
                }}
              >
                {randomTag}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </a>
  );
};

export default TraderCoin;
