import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import React, { useContext, useEffect, useState } from "react";
import oldStyles from "./oldStyles";
import { commafy, origin } from "./utils";
import AppContext from "./AppContext";
import { css, StyleSheet } from "aphrodite";
import { PhantomWalletName } from "@solana/wallet-adapter-phantom";
import Holding2 from "./Holding2";

const styles = StyleSheet.create({
  primaryButton: {
    background: "rgb(32 129 226)",
    borderRadius: "20px",
    padding: "10px 20px",
    fontWeight: "900",
    textAlign: "center",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgb(69, 145, 222)",
    },
  },
});

function combineByMint(holdings, tokenHoldings) {
  // Create a Map keyed by `mint`
  const map = new Map();

  // 1. Populate the map with `holdings`
  for (const h of holdings) {
    map.set(h.mint, {
      holding: h,
      tokenHolding: null,
    });
  }

  // 2. Merge in `tokenHoldings`
  for (const t of tokenHoldings) {
    if (map.has(t.mint)) {
      // If we already have an entry for this mint, just add tokenHolding
      map.get(t.mint).tokenHolding = t;
    } else {
      // Otherwise, create a new entry with `holding: null`
      map.set(t.mint, {
        holding: null,
        tokenHolding: t,
      });
    }
  }

  // 3. Convert the map back to an array
  return Array.from(map.values());
}

const Holdings = ({}) => {
  const [tokens, setTokens] = useState([]);
  const [buyAmount, setBuyAmount] = useState(0.1);
  const [fetching, setFetching] = useState(false);
  const [sellPercentage, setSellPercentage] = useState(() => {
    const storedSellPercentage = localStorage.getItem("sellPercentage");
    return storedSellPercentage !== null
      ? parseFloat(storedSellPercentage)
      : 100;
  });

  useEffect(() => {
    localStorage.setItem("sellPercentage", sellPercentage);
  }, [sellPercentage]);

  const {
    socket,
    isMobile,
    socketOpen,
    setHoldings,
    holdings,
    authToken,
    setTokenHoldings,
    tokenHoldings,
  } = useContext(AppContext);
  const [subscribed, setSubscribed] = useState(false);
  const [tokensSet, setTokensSet] = useState(false);

  const { publicKey, connect, disconnect, select } = useWallet();

  useEffect(() => {
    (async function () {
      if (authToken) {
        try {
          setFetching(true);
          const response = await fetch(`${origin}/tokens`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          });

          if (!response.ok) {
            console.error("Failed to fetch tokens.");
            setTokens([]);
            setHoldings([]);
            return;
          }

          let { tokens, tokenHoldings } = await response.json();
          setTokenHoldings(tokenHoldings);
          setHoldings(tokens);
          setTokensSet(true);
          setFetching(false);
        } catch (error) {
          console.error("Error fetching tokens:", error);
          setTokens([]);
          setHoldings([]);
          setFetching(false);
        }
      } else {
        setTokens([]);
        setHoldings([]);
        setFetching(false);
      }
    })();
  }, [authToken]);

  useEffect(() => {
    if (!subscribed && socketOpen && holdings.length > 0) {
      const tokensToAdd = {
        add_mints: holdings.map((token) => token.mint),
      };
      socket.send(JSON.stringify(tokensToAdd));
      setSubscribed(true);
    }
    if (subscribed && !socketOpen) {
      setSubscribed(false);
    }
  }, [subscribed, socketOpen, holdings, socket]);

  let totalValue = 0;
  // tokens.forEach((token) => {
  //   const value = (token.quantity / 1_000_000_000) * token.marketCap;

  //   totalValue += value;
  // });

  const combinedHoldings = combineByMint(holdings, tokenHoldings);

  const handleConnect = async () => {
    try {
      select(PhantomWalletName);
      await connect();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div
      style={{
        ...oldStyles.box,
        flex: 1,
        flexGrow: 1,
        boxSizing: "border-box",
        minWidth: "100%",
        minHeight: "400px",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "25px",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ fontWeight: "700", fontSize: "20px" }}>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "400",
              color: "rgba(255, 255, 255, 0.5)",
              marginBottom: "5px",
            }}
          >
            {`Wallet Value`}
          </div>
          {`$${commafy(Math.floor(totalValue))}`}
        </div>

        <div>
          <div style={{ display: "flex", alignItems: "center", gap: "50px" }}>
            {/* <div
              style={{
                display: "inline-flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <div>
                <div style={{ fontSize: "12px", fontWeight: "600" }}>
                  Buy Amount Sol
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "rgba(255, 255, 255, 0.6)",
                    marginTop: "5px",
                  }}
                >
                  Enter Sol Amount
                </div>
              </div>
              <div
                style={{
                  border: "1px solid rgba(255, 255, 255, 0.05)",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  gap: "5px",
                  padding: "10px",
                  width: "120px",
                }}
              >
                <img
                  style={{ borderRadius: "100%" }}
                  width={20}
                  height={20}
                  src={`${window.location.origin}/sol.png`}
                />
                <input
                  style={{ color: "white" }}
                  placeholder="0"
                  value={buyAmount || ""}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only numbers and a single decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      setBuyAmount(value);
                    }
                  }}
                />
              </div>
            </div> */}
            <div
              style={{
                display: "flex",
                gap: "15px",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "10px",
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "600",
                    minWidth: "110px",
                  }}
                >
                  🏷️ Sell Percentage (%)
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    color: "rgba(255, 255, 255, 0.6)",
                    marginTop: "5px",
                  }}
                >
                  What % to sell
                </div>
              </div>
              <div
                style={{
                  border: "1px solid rgba(255, 255, 255, 0.05)",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  gap: "5px",
                  padding: "10px",
                  position: "relative",
                  width: "120px",
                }}
              >
                <input
                  style={{ color: "white" }}
                  placeholder="0"
                  value={sellPercentage || ""}
                  onChange={(e) => {
                    const value = e.target.value;

                    // Allow only numbers and a single decimal point
                    if (/^\d*\.?\d*$/.test(value)) {
                      setSellPercentage(value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {!publicKey && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "20px",
            flexGrow: 1,
          }}
        >
          <div
            style={{
              color: "rgba(255, 255, 255, 0.6)",
              flexGrow: 1,
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid rgba(255, 255, 255, 0.08)",
              borderRadius: "10px",
            }}
          >
            Connect wallet to view holdings
          </div>
          <div onClick={handleConnect} className={css(styles.primaryButton)}>
            {" "}
            Connect Wallet{" "}
          </div>
        </div>
      )}
      {publicKey && combinedHoldings.length === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "20px",
            flexGrow: 1,
          }}
        >
          <div
            style={{
              color: "rgba(255, 255, 255, 0.6)",
              flexGrow: 1,
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid rgba(255, 255, 255, 0.08)",
              borderRadius: "10px",
            }}
          >
            {fetching ? "Loading Holdings..." : `No Holdings Found`}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          width: "100%",
          overflowY: "auto",
          scrollbarWidth: "none",
        }}
      >
        {combinedHoldings.map((combinedHolding) => (
          <Holding2
            key={combinedHolding.holding.mint}
            isMini={false}
            holding={combinedHolding.holding}
            tokenHolding={combinedHolding.tokenHolding}
            buyAmount={0}
            sellPercentage={sellPercentage}
          />
        ))}
      </div>
    </div>
  );
};

export default Holdings;
