import { useWallet } from "@solana/wallet-adapter-react";
import { css, StyleSheet } from "aphrodite";
import React, { useContext, useState } from "react";
import oldStyles from "./oldStyles";
import Toast from "./Toast";
import AppContext from "./AppContext";

const styles = StyleSheet.create({
  primaryButton: {
    background: "rgb(32 129 226)",
    borderRadius: "20px",
    padding: "10px 20px",
    fontWeight: "900",
    textAlign: "center",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgb(69, 145, 222)",
    },
  },
});

let toastId = 1;

const ReferralWidget = () => {
  const { publicKey } = useWallet();
  const [toasts, setToasts] = useState([]);

  const { isMobile } = useContext(AppContext);

  const address = publicKey?.toBase58() ?? "";

  const currentOrigin = window.location.origin;
  const referralLink = `${currentOrigin}/?r=${address}`;

  const handleCopyText = () => {
    navigator.clipboard
      .writeText(referralLink)
      .then(() => {
        console.log("copied");
        setToasts((t) => [
          ...t,
          {
            title: "Copied",
            icon: "check",
            information: "You have copied the referral link",
            id: toastId++,
          },
        ]);
        // this.setState({ copySuccess: 'Text copied to clipboard!' });
      })
      .catch((err) => {
        // console.error('Failed to copy: ', err);
        // this.setState({ copySuccess: 'Failed to copy text.' });
      });
  };

  const renderToast = (toast) => {
    return (
      <Toast
        key={toast.id}
        title={toast.title}
        information={toast.information}
        icon={toast.icon}
        link={toast.link}
      />
    );
  };

  const renderToasts = () => {
    return <div>{toasts.map(renderToast)}</div>;
  };

  return (
    <div style={{ ...oldStyles.box, height: "200px" }}>
      <div style={{ fontWeight: "700", fontSize: "18px", textAlign: "center" }}>
        Refer a Friend
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "20px",
          padding: "0 20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "10px 20px",
            backgroundColor: "rgb(0, 0, 0, 0.3)",
            flex: 1,
            borderRadius: "10px",
            display: "flex",
            maxWidth: "100%",
          }}
        >
          <div style={{ flex: 1, whiteSpace: "nowrap", overflow: "hidden" }}>
            {isMobile
              ? `https://flakeswap.com/?r=${address.substr(0, 5)}...`
              : referralLink}
          </div>
        </div>
        <button onClick={handleCopyText} className={css(styles.primaryButton)}>
          Copy
        </button>
      </div>
      <div
        style={{
          color: "rgb(106, 106, 109)",
          fontSize: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5px",
        }}
      >
        <div>Receive 10% 💧's from all referral volume</div>
      </div>
      {renderToasts()}
    </div>
  );
};

export default ReferralWidget;
