import React, { useContext, useEffect, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import * as web3 from "@solana/web3.js";
import { PhantomWalletName } from "@solana/wallet-adapter-phantom";
import { css, StyleSheet } from "aphrodite";
import Search from "./Search";
import Toast from "./Toast";
import HamburgerMenu from "./HamburgerMenu";
import { commafy, origin } from "./utils";
import AppContext from "./AppContext";

let toastId = 1;

const styles = StyleSheet.create({
  walletButton: {
    padding: "10px 20px",
    borderRadius: "10px",
    width: "180px",
    textAlign: "center",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    border: "1px solid rgba(255,255,255,.05)",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(255,255,255,.1)",
      border: "1px solid rgba(255,255,255,.1)",
    },
  },
  primaryButton: {
    background: "rgb(32 129 226)",
    borderRadius: "20px",
    padding: "10px 20px",
    fontWeight: "900",
    textAlign: "center",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgb(69, 145, 222)",
    },
  },
  navButton: {
    color: "rgb(106, 106, 109)",
    textDecoration: "none",
    transition: "0.3s all",
    cursor: "pointer",
    ":hover": {
      color: "rgb(255, 255, 255)",
    },
  },
  active: {
    color: "#FFF",
  },
  flakeCount: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.05)",
    border: "1px solid rgba(255,255,255,.05)",
    padding: "8px",
    borderRadius: "10px",
    transition: "0.3s all",
    position: "relative",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(255,255,255,.1)",
      border: "1px solid rgba(255,255,255,.1)",
    },
  },
});

const createReferral = ({ referrer, referee }) => {
  try {
    let body = {
      referrer,
      referee,
    };
    const headers = { "Content-Type": "application/json" };
    fetch(`${origin}/referrals`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("Got response from authenticate", res);
        if (res.success) {
        } else {
        }
      })
      .catch((err) => {});
  } catch (e) {
    return Promise.resolve({});
  }
};

const getAccount = async ({ address }) => {
  try {
    const headers = { "Content-Type": "application/json" };
    const response = await fetch(`${origin}/accounts/${address}`, {
      method: "GET",
      headers: headers,
    });
    return response.json();
  } catch (err) {
    return Promise.resolve({});
  }
};

function getQueryParamValue(key) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
}

const getNewChallenge = async (publicKey) => {
  try {
    const headers = { "Content-Type": "application/json" };
    const response = await fetch(
      `${origin}/challenge?public_key=${publicKey}`,
      {
        method: "GET",
        headers: headers,
      }
    );
    return response.json(); // This should return the challenge
  } catch (err) {
    console.error("Failed to fetch new challenge:", err);
    return Promise.resolve({}); // Return an empty object on error
  }
};

// Updated verifySignature function
const verifySignature = async (
  publicKey,
  signature,
  challenge,
  setAuthToken
) => {
  try {
    const referral = localStorage.getItem("referral");
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify({
      public_key: publicKey,
      signature: signature,
      challenge: challenge,
      ...(referral && { referral }),
    });

    const response = await fetch(`${origin}/verify`, {
      method: "POST",
      headers: headers,
      body: body,
    });

    const data = await response.json();

    // Store the token locally if verification is successful
    if (response.ok && data.token) {
      localStorage.setItem("authToken", data.token);
      localStorage.setItem(publicKey, data.token);
      setAuthToken(data.token);
      console.log("Verification successful. Token stored.");
      return data;
    } else {
      console.error("Verification failed:", data.error);
      return {};
    }
  } catch (err) {
    console.error("Failed to verify signature:", err);
    return {};
  }
};

const fetchUserAndSet = async (setUser, address) => {
  try {
    // Retrieve the token from local storage
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found. Please verify your signature first.");
      return;
    }

    // Make a request to the /user endpoint
    const response = await fetch(`${origin}/user`, {
      method: "GET", // Typically, GET is used for user retrieval
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (response.ok) {
      console.log("User data retrieved successfully:", data);
      // Call setUser with the user data
      console.log("DATA", data);
      if (data.user === null) {
        localStorage.removeItem(address);
        localStorage.removeItem("authToken");
      }
      setUser(data.user || {});
    } else {
      localStorage.removeItem(address);
      localStorage.removeItem("authToken");
      console.error("Failed to retrieve user data:", data.error);
    }
  } catch (err) {
    console.error("Error accessing /user endpoint:", err);
  }
};

const startAuthentication = async (wallet, setAuthToken) => {
  // Get new challenge
  console.log("here");
  const challengeResponse = await getNewChallenge(wallet.publicKey);
  if (!challengeResponse.challenge) {
    console.error("No challenge received");
    return;
  }
  const challenge = challengeResponse.challenge;

  try {
    const encodedMessage = new TextEncoder().encode(challenge);
    const signatureBytes = await wallet.signMessage(encodedMessage, "utf8");
    const signatureBase64 = btoa(
      String.fromCharCode.apply(null, new Uint8Array(signatureBytes))
    );
    console.log("Signature:", signatureBase64);

    // Verify the signature
    const verifyResponse = await verifySignature(
      wallet.publicKey,
      signatureBase64,
      challenge,
      setAuthToken
    );
    if (verifyResponse.token) {
      console.log("Authenticated successfully. JWT:", verifyResponse.token);
    } else {
      console.error("Authentication failed");
    }
  } catch (error) {
    console.error("Error signing message:", error);
  }
};

const signMessage = async (wallet, setAuthToken) => {
  console.log("h1");
  if (!wallet.connected) {
    console.log("Connect to a wallet first.");
    return;
  }
  await startAuthentication(wallet, setAuthToken);
};

function shortenString(str, startLength = 4, endLength = 4) {
  if (str.length <= startLength + endLength) {
    return str; // No truncation needed if the string is too short
  }
  const start = str.slice(0, startLength);
  const end = str.slice(-endLength);
  return `${start}...${end}`;
}

const lamportsToSol = (lamports) => {
  const sol = lamports / web3.LAMPORTS_PER_SOL; // Convert lamports to SOL
  return sol.toPrecision(4); // Format to 4 significant digits
};

const Header = ({ page, setPage, setCoinSelected }) => {
  const {
    isMobile,
    account,
    setAccount,
    setShowModal,
    user,
    setUser,
    setBalance,
    balance,
    authToken,
    setAuthToken,
    socket,
  } = useContext(AppContext);
  const { connection } = useConnection();
  const wallet = useWallet();

  const [showRewardInfo, setShowRewardInfo] = useState(false);
  const { publicKey, connect, disconnect, select } = useWallet();
  const [searchFocused, setSearchFocused] = useState(false);
  const [toasts, setToasts] = useState([]);

  const [totalHoldings, setTotalHoldings] = useState(0);
  const [multiplier, setMultiplier] = useState(1);

  const [address, setAddress] = useState("");

  console.log("loaded");

  useEffect(() => {
    // Access the query string from the current URL
    const params = new URLSearchParams(window.location.search);
    const referralCode = params.get("r"); // Get the value of 'r'

    if (referralCode) {
      // Save the referral code into local storage
      localStorage.setItem("referral", referralCode);
      console.log(`Referral code "${referralCode}" saved to local storage.`);
    }
  }, []);

  useEffect(() => {
    if (user?.solanaKeypair?.publicKey && socket) {
      const msg = { add_wallet: user.solanaKeypair.publicKey };
      socket.send(JSON.stringify(msg));
    }
  }, [socket, user]);

  useEffect(() => {
    if (publicKey) {
      console.log("setting address", publicKey.toBase58());
      setAddress(publicKey.toBase58());
    } else {
      setAddress("");
    }
  }, [publicKey]);

  const doIt = async (connection, user, setBalance) => {
    try {
      if (user) {
        const newBalance = await connection.getBalance(
          new web3.PublicKey(user.solanaKeypair.publicKey)
        );
        setBalance(newBalance);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // If user or user.solanaKeypair isn't available, exit early
    if (!user || !user.solanaKeypair || !connection) return;

    // Check balance immediately once
    doIt(connection, user, setBalance);

    // Then set up an interval to check balance every 10s
    const intervalId = setInterval(() => {
      doIt(connection, user, setBalance);
    }, 10000);

    // Cleanup interval on component unmount or dependency change
    return () => clearInterval(intervalId);
  }, [user, setBalance]);

  const attemptVerify = (address) => {
    const authTokenForAddress = localStorage.getItem(address);
    if (authTokenForAddress) {
      localStorage.setItem("authToken", authTokenForAddress);
    }
    console.log("changed?", address, authTokenForAddress);
    if (!authTokenForAddress) {
      signMessage(wallet, setAuthToken);
    } else {
      setAuthToken(authTokenForAddress);
    }
  };

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        // Retrieve the token from local storage
        const token = localStorage.getItem("authToken");

        // Prepare headers, including Authorization if the token exists
        const headers = {
          "Content-Type": "application/json",
          ...(token && { Authorization: `Bearer ${token}` }), // Add Authorization header if token is present
        };

        // Make the request to the leaderboard endpoint
        const response = await fetch(`${origin}/leaderboard`, {
          method: "GET",
          headers: headers,
        });

        const data = await response.json();

        if (response.ok) {
          // Update state with the leaderboard data
          setTotalHoldings(data.totalHoldings || 0);
          setMultiplier(data.multiplier || 1);
        } else {
          console.error("Failed to fetch leaderboard:", data.error);
        }
      } catch (err) {
        console.error("Failed to fetch leaderboard:", err);
      }
    };

    fetchLeaderboard();
  }, []);

  useEffect(() => {
    // const referrer = getQueryParamValue("r");
    // if (referrer && address) {
    //   createReferral({ referrer, referee: address });
    // }
    if (!address) {
      localStorage.removeItem("authToken");
      setAuthToken(null);
    } else {
      attemptVerify(address);
    }
  }, [address]);

  const findAccount = async ({ address }) => {
    const res = await getAccount({ address });
    setAccount(res?.account ?? {});
  };

  useEffect(() => {
    if (authToken) {
      console.log("fetching user");
      // findAccount({ address });
      fetchUserAndSet(setUser, address);
    } else {
      setUser({});
    }
  }, [authToken, address]);

  const handleConnect = async (address) => {
    if (address) {
      attemptVerify(address);
    } else {
      try {
        select(PhantomWalletName);
        await connect();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const handleDisconnect = async () => {
    try {
      await disconnect();
    } catch (e) {
      console.error(e);
    }
  };

  const renderToast = (toast) => {
    return (
      <Toast
        key={toast.id}
        title={toast.title}
        information={toast.information}
        icon={toast.icon}
        link={toast.link}
      />
    );
  };

  const renderToasts = () => {
    return <div>{toasts.map(renderToast)}</div>;
  };

  if (isMobile) {
    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          flexBasis: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <a
          href={"/"}
          style={{
            fontSize: "30px",
            color: "#4fff69",
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
        >
          <img src={`${window.location.origin}/flake.png`} height={40} />
        </a>
        <Search
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
          searchFocused={searchFocused}
          setCoinSelected={setCoinSelected}
        />
        <HamburgerMenu setPage={setPage} />
      </div>
    );
  }

  const copy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Copied to clipboard:", mint);
      })
      .catch((err) => {
        console.error("Failed to copy text:", err);
      });
    setToasts((t) => [
      ...t,
      {
        title: "Copied to clipboard",
        information: `Successfully copied!`,
        icon: "check",
        id: toastId++,
      },
    ]);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        flexBasis: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "30px",
          fontWeight: "700",
          alignItems: "center",
        }}
      >
        <a
          href={"/"}
          style={{
            fontSize: "30px",
            color: "#4fff69",
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
          }}
        >
          <img src={`${window.location.origin}/flake.png`} height={40} />
        </a>
        <div style={{ display: "flex", gap: "30px" }}>
          <div
            onClick={() => setPage("dashboard")}
            className={`${css(styles.navButton)} ${
              page === "dashboard" ? css(styles.active) : {}
            }`}
          >
            Dashboard
          </div>
          <div
            onClick={() => setPage("holdings")}
            className={`${css(styles.navButton)} ${
              page === "holdings" ? css(styles.active) : {}
            }`}
            style={page === "holdings" ? { color: "#FFF" } : {}}
          >
            Holdings
          </div>
          <div
            onClick={() => setPage("leaderboard")}
            className={`${css(styles.navButton)} ${
              page === "leaderboard" ? css(styles.active) : {}
            }`}
            style={page === "leaderboard" ? { color: "#FFF" } : {}}
          >
            Leaderboard
          </div>
          <a
            href={"https://docs.flakeswap.com/"}
            target={"_blank"}
            className={`${css(styles.navButton)}`}
          >
            Docs
          </a>
          {/* <SolanaSignMessage /> */}
          {/* <div onClick={() => testSell()}>Sell</div>
          <div onClick={() => testBuy()}>Buy</div> */}
        </div>
      </div>
      <Search
        onFocus={() => setSearchFocused(true)}
        onBlur={() => setSearchFocused(false)}
        searchFocused={searchFocused}
        setCoinSelected={setCoinSelected}
      />
      <div
        style={{
          display: "flex",
          gap: "20px",
          fontWeight: "900",
          alignItems: "center",
        }}
      >
        {/*<div style={{fontWeight: '400', color: 'rgb(106, 106, 109)'}}>*/}
        {/*  {address ? address : "Not Connected"}*/}
        {/*</div>*/}
        {/* {account?.airdrop_available && !account?.airdrop_claimed && (
          <div
            onClick={() => setShowModal(true)}
            className={css(styles.primaryButton)}
          >
            Claim Airdrop
          </div>
        )} */}
        {user && user.solanaKeypair && (
          <>
            <div
              className={css(styles.flakeCount)}
              onClick={() => copy(user?.solanaKeypair?.publicKey)}
            >
              {shortenString(user?.solanaKeypair?.publicKey)}
              <img
                src={`${window.location.origin}/copying.png`}
                alt="Copy Icon"
                height={14}
                style={{
                  filter: "invert(100%)", // Makes the image white
                  opacity: 0.8, // Matches the button transparency
                }}
              />
            </div>
            <div
              className={css(styles.flakeCount)}
              onClick={() => setShowModal("withdraw")}
            >
              <img
                src={`${window.location.origin}/sol.png`}
                alt="Solana Logo"
                style={{
                  borderRadius: "50%",
                  width: "20px", // Adjust size as needed
                  height: "20px",
                }}
              />{" "}
              {lamportsToSol(balance)}
            </div>
          </>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div
            onMouseOver={() => setShowRewardInfo(true)}
            onMouseOut={() => setShowRewardInfo(false)}
            className={css(styles.flakeCount)}
          >
            <img src={`${window.location.origin}/flake.png`} height={18} />
            <div>
              <div
                style={{ color: "#FFF", fontSize: "12px", fontWeight: "900" }}
              >
                {`${multiplier}x bonus`}
              </div>
              <div
                style={{
                  color: "#FFF",
                  fontSize: "10px",
                  color: "rgba(255, 255, 255, 0.6)",
                }}
              >
                {`${totalHoldings.toLocaleString()}`}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {renderToasts()}
          <div
            onMouseOver={() => setShowRewardInfo(true)}
            onMouseOut={() => setShowRewardInfo(false)}
            className={css(styles.flakeCount)}
          >
            {/* <img src={`${window.location.origin}/shard.png`} height={18} /> */}
            <div style={{ color: "#FFF", fontSize: "16px", fontWeight: "900" }}>
              💧 {commafy(Math.floor(user.points ?? 0))}
            </div>
            {showRewardInfo && account.bonus_available > 0 && (
              <div
                style={{
                  position: "absolute",
                  boxShadow: "rgba(0, 0, 0, 0.4) 0px 4px 12px",
                  fontSize: "14px",
                  top: "46px",
                  left: "-110px",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  backgroundColor: "#0f1018",
                  border: "1px solid " + "#35353f",
                  padding: "20px",
                  borderRadius: "10px",
                  transition: "0.3s all",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "900",
                    fontSize: "16px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "400",
                      color: "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    Flake Progress
                  </div>
                  <div
                    style={{ display: "flex", gap: "5px", fontSize: "14px" }}
                  >
                    {commafy(Math.floor(account.bonus_available))}
                    <img
                      src={`${window.location.origin}/flake.png`}
                      height={14}
                    />
                    Bonus
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: "#000",
                    width: "300px",
                    height: "16px",
                    borderRadius: "20px",
                  }}
                >
                  <div
                    style={{
                      width: `${Math.floor(
                        account.bonus_earned / account.bonus_available
                      )}%`,
                      borderRadius: "20px",
                      height: "16px",
                      background:
                        "linear-gradient(350deg, rgba(79,214,255,1) 0%, rgba(48,228,244,1) 19%, rgba(59,175,237,1) 100%)",
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "900",
                    fontSize: "16px",
                  }}
                >
                  <div>
                    {`${commafy(Math.floor(account.bonus_earned))} / ${commafy(
                      Math.floor(account.bonus_available)
                    )}`}
                  </div>
                  <div>
                    {`${Math.floor(
                      account.bonus_earned / account.bonus_available
                    )}%`}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          onClick={authToken ? handleDisconnect : () => handleConnect(address)}
          className={css(styles.walletButton)}
        >
          {authToken ? "Disconnect Wallet" : "Connect Wallet"}
        </div>
      </div>
      {searchFocused && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            left: "-20px",
            height: "calc(100svh - 80px)",
            zIndex: 30,
            top: "60px",
            backdropFilter: "blur(5px)",
          }}
        ></div>
      )}
    </div>
  );
};

export default Header;
